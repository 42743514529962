import React from 'react';
import Routes from './Routes/app.routes';

export const App: React.FC = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
