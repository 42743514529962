import { getEventDateCount, getEvents } from './../events/events.action';
import { BUILDING_NAME, TOAST_MESSAGES } from './../../constants';
import {
  generateReportApi,
  getBuildingBlockApi,
  validateBuildingAccessApi,
  validateBuildingCodeApi,
  validateClusterCodeApi,
} from './building.service';
import * as toastTypes from '../toast/toast.constant';
import * as buildingTypes from './building.constant';
import * as loadingTypes from '../loader/loader.constant';
import history from '../../Routes/history';
import Config from '../../config/environment';
import { openNoAccessModal } from '../modal/modal.action';
import { IGenerateReport } from '../../models/building';

export const validateBuildingCode = (
  buildingName: string,
  date: string,
  param: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (buildingName) {
    const validatedBuilding = await validateBuildingCodeApi(buildingName);
    if (validatedBuilding.data) {
      await getEvents(buildingName, date, param)(dispatch);
      await getEventDateCount(buildingName, date)(dispatch);
      dispatch({
        type: buildingTypes.VALIDATE_BUILDING,
        payload: validatedBuilding.data,
        code: buildingName,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    } else if (validatedBuilding?.['error']?.['code'] === 404) {
      await validateClusterCode(buildingName)(dispatch);
    } else if (
      validatedBuilding?.['error']?.['code'] !== 401 &&
      validatedBuilding?.['error']?.['code'] !== 404
    ) {
      window.location.href = `https://${Config.FRONTEND_DOMAIN}/error-message`;
    }
  } else {
    dispatch({
      type: buildingTypes.VALIDATE_BUILDING,
      code: BUILDING_NAME,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const validateClusterCode = (clusterName: string) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (clusterName) {
    const validatedCluster = await validateClusterCodeApi(clusterName);
    if (validatedCluster.data) {
      dispatch({
        type: buildingTypes.VALIDATE_CLUSTER,
        payload: validatedCluster.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      history.push('/cluster');
    } else if (validatedCluster?.['error']?.['code'] !== 401) {
      window.location.href = `https://${Config.FRONTEND_DOMAIN}/error-message`;
    }
  }
};

export const getBuildingBlocks = (buildingCode: string) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getBuildingBlockApi(buildingCode);
  if (detail.data) {
    dispatch({
      type: buildingTypes.GET_BUILDING_BLOCKS,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const validateBuildingCodeBeforeLogin = (buildingName: string) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const validatedBuilding = await validateBuildingCodeApi(buildingName);
  if (validatedBuilding.data) {
    dispatch({
      type: buildingTypes.VALIDATE_BUILDING,
      payload: validatedBuilding.data,
      code: buildingName,
    });
  } else {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: validatedBuilding?.['error']?.['message'],
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const validateBuildingAccess = (buildingName: string) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const validateAccess = await validateBuildingAccessApi(buildingName);
  if (validateAccess.data) {
    if (validateAccess.data.isRelated) {
      dispatch({
        type: buildingTypes.VALIDATE_BUILDING_ACCESS,
        payload: validateAccess.data.isRelated,
      });
    } else {
      dispatch({
        type: buildingTypes.VALIDATE_BUILDING_ACCESS,
        payload: validateAccess.data.isRelated,
      });
      await openNoAccessModal()(dispatch);
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const generateReport = (
  data: IGenerateReport,
  buildingCode: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const reportData = await generateReportApi(data, buildingCode);
  if (reportData?.['data']) {
    dispatch({
      type: buildingTypes.GENERATE_CLIENT_REPORT,
      payload: reportData?.['data'],
    });
  } else {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.report.noRecord,
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const clearClientReport = () => async (dispatch) => {
  dispatch({
    type: buildingTypes.CLEAR_CLIENT_REPORT,
  });
};
