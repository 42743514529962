import { authHttp, http } from '../common/http.service';
import { AxiosResponse } from 'axios';
import {
  IContactAdmin,
  IContactAdminResponse,
  ITermConditionResponse,
  IUserDetail,
  IUserDetailResponse,
} from '../../models';
import { BUILDING_NAME } from './../../constants';

export const getUserDetailApi = async (
  buildingName: string = BUILDING_NAME
): Promise<IUserDetailResponse> => {
  return authHttp
    .get(`tenant/account/${buildingName}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserDetailResponse>) => {
      return response.data;
    });
};

export const updateUserDetailApi = async (
  data: IUserDetail,
  buildingName: string = BUILDING_NAME
): Promise<IUserDetailResponse> =>
  authHttp
    .put(`/tenant/account/${buildingName}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserDetailResponse>) => {
      return response.data;
    });

export const updateTermOfServiceApi = async (): Promise<
  ITermConditionResponse
> => {
  return http
    .post(`/tenant/terms-of-service/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ITermConditionResponse>) => {
      return response.data;
    });
};

export const contactAdminApi = async (
  data: IContactAdmin
): Promise<IContactAdminResponse> => {
  return http
    .post(`/contact-admin/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IContactAdminResponse>) => {
      return response.data;
    });
};
