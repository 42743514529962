import React from 'react';
import ModalPopup from '../ReactModal/Modal';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/modal/modal.action';
import { markUnRsvped } from '../../redux/events';
import { ILoaderState } from '../../redux/loader';
import './EventListing.scss';

const UnrsvpModal: React.FC<{
  modalState: boolean;
  eventId: number;
  buttonColor: string | null;
  buttonText: string | null;
  tab: string;
}> = ({ modalState, eventId, buttonColor, buttonText, tab }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const close = async () => {
    await dispatch(closeModal());
  };

  const unRsvpEvent = async () => {
    await dispatch(markUnRsvped(eventId, tab));
    close();
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="p-8 md:p-12 pt-12 h-56 w-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <div className="grid grid-cols-1 gap-y-0 unrsvp-wrapper">
        <PrimaryLabel
          labelText="Are you sure you want to unRSVP from this event?"
          className="sub-header"
        />
        <div className="col-span-2 mt-16">
          <Button
            label="No, cancel"
            className="w-32 block btn-no"
            onClick={close}
            backgroundColor={buttonColor}
            buttonText={buttonText}
          />
          <Button
            label="Yes, unRSVP"
            className={`block px-simple-button mr-2 ${
              loading && 'pointer-events-none'
            }`}
            onClick={unRsvpEvent}
            btnBorderColor={buttonColor}
            buttonText={buttonColor}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default UnrsvpModal;
