// tslint:disable: semicolon
import React, { useState, useEffect, useRef } from 'react';
import { Picker } from 'emoji-mart';
import { getImageUrl } from '../../../utils';

const useOutsideAlerter = (ref, toggleEmojiModalState) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleEmojiModalState(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const EmojiPicker = ({ addEmoji, disabled, hideEmoji }) => {
  const [emojiModalState, toggleEmojiModalState] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLSpanElement>(null);
  useOutsideAlerter(wrapperRef, toggleEmojiModalState);
  useEffect(() => {
    toggleEmojiModalState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideEmoji]);
  return (
    <>
      {!disabled && emojiModalState && (
        <span className="emoji-picker" ref={wrapperRef}>
          <Picker
            onSelect={addEmoji}
            emojiTooltip={true}
            title="Emoji"
            emojiSize={20}
          />
        </span>
      )}
      <p
        className="emoji-btn"
        onClick={() => toggleEmojiModalState(!emojiModalState)}
      >
        <img src={getImageUrl('ConsumerApp/emoji.svg', true)} alt="emoji" />
      </p>
    </>
  );
};

export default EmojiPicker;
