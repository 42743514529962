import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { checkBuildingWhiteLabeled } from '../../utils';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/modal/modal.action';
import './GenerateReport.scss';
import {
  clearClientReport,
  generateReport,
  IBuldingState,
} from '../../redux/building';
import {
  currentMonth,
  currentYear,
  EN,
  MonthOptions,
  yearOptions,
} from '../../constants';
import { ThemeObjectType } from '../../enums';
import { IGenerateReport } from '../../models/building';
import { TinyObject } from '../../models';

const GenerateReport: React.FC<{ modalState: boolean }> = ({ modalState }) => {
  const [currentMonthValue, setCurrentMonth] = useState<TinyObject>();
  const [currentYearValue, setCurrentYear] = useState<TinyObject>();
  const { register, handleSubmit, setValue } = useForm<IGenerateReport>();
  const { buildingDetail, clientReport } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );

  const dispatch = useDispatch();
  const onSubmit = async (data: IGenerateReport) => {
    const params = {
      month: data['month'] ?? currentMonthValue.id,
      year: data['year'] ?? currentYearValue.id,
    };
    await dispatch(generateReport(params, buildingDetail?.['code']));
    await close();
  };

  const close = async () => {
    await dispatch(closeModal());
  };

  // Set month and year dropdown value on load
  useEffect(() => {
    const monthObj = MonthOptions.filter((d) => d.id === currentMonth);
    const yearObj = yearOptions.filter((d) => d.id === currentYear);
    setCurrentMonth({
      id: monthObj[0]['id'],
      name: monthObj[0]['name'],
    });
    setCurrentYear({
      id: yearObj[0]['id'],
      name: yearObj[0]['name'],
    });
  }, []);

  // Download file after api response
  useEffect(() => {
    if (Object.keys(clientReport).length > 0) {
      const fileDownload = require('js-file-download');
      fileDownload(
        clientReport,
        `${buildingDetail?.['code']}-${currentMonthValue?.['name']}-${currentYearValue?.['name']}.csv`
      );
      (async () => await dispatch(clearClientReport()))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientReport]);

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="report-modal h-full md:h-auto rounded-none md:rounded-lg transform translate-y-0 md:translate-y-20"
      shouldCloseOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-2 gap-y-0 report-wrapper">
          <PrimaryLabel
            labelText={EN.report.Title}
            className="sub-header report-title"
          />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-2 gap-y-0">
            <div className="pt-16">
              <PrimaryLabel labelText={EN.report.Month} />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label=""
                options={MonthOptions}
                ref={() => register({ name: 'month' })}
                onOptionChange={async (e) => {
                  setValue('month', e['id']);
                  setCurrentMonth({
                    id: e['id'],
                    name: e['name'],
                  });
                }}
                placeholder=""
                value={currentMonthValue}
              />
            </div>
            <div className="pt-4 md:pt-16">
              <PrimaryLabel labelText={EN.report.Year} />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label=""
                options={yearOptions}
                ref={() => register({ name: 'year' })}
                onOptionChange={async (e) => {
                  setValue('year', Number(e['id']));
                }}
                placeholder=""
                value={currentYearValue}
              />
            </div>
            <div className="pt-4 md:pt-20 inline-flex">
              <Button
                label={EN.report.Download}
                className={`w-full block btn-download`}
                backgroundColor={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_COLOR
                )}
                buttonText={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_TEXT
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </ModalPopup>
  );
};

export default GenerateReport;
