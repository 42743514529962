import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import {
  checkPastEvent,
  getDayAndTime,
  getImageUrl,
  checkLiveEvent,
} from '../../utils';
import './EventListing.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  autoMarkAttendance,
  IEventState,
  markRsvped,
} from '../../redux/events';
import { EN, EVENT_DAY_FORMAT, EVENT_TIME_FORMAT } from '../../constants';
import { IBuldingState } from '../../redux/building';
import { useLocation } from 'react-router-dom';
import { openModal, openUnrsvpModal } from '../../redux/modal/modal.action';

const EventDetail: React.FC<{
  buttonColor?: string | null;
  buttonText?: string | null;
  tenantUrl: string | null;
  isLoggedIn: boolean;
  buildingAccess: boolean;
}> = ({ buttonColor, buttonText, tenantUrl, isLoggedIn, buildingAccess }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);
  const page = queryParams.get('pageNo');
  const { eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { buildingDetail } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );

  const handleMarkRsvp = async () => {
    if (isLoggedIn) {
      if (eventDetail?.['details']) {
        const data = {
          buildingCode: buildingDetail?.code,
          reference: 'app',
        };
        await dispatch(markRsvped(data, eventDetail.details.id, Number(page)));
      }
    } else {
      dispatch(openModal());
    }
  };

  const handleUnMarkRsvp = async () => {
    await dispatch(openUnrsvpModal());
  };

  const openLiveStream = async () => {
    const eventId = eventDetail?.details?.id;
    if (eventId) {
      if (
        checkPastEvent(eventDetail?.['details']?.['startDatetime']) &&
        checkLiveEvent(eventDetail?.['details']?.['endDatetime'])
      ) {
        const data = {
          buildingCode: buildingDetail?.code,
          reference: 'app',
        };
        await dispatch(autoMarkAttendance(eventId, data));
      }
      if (tenantUrl && tenantUrl.includes('zoom')) {
        window.open(tenantUrl);
      } else {
        window.open(
          `/livestream/${eventId}/?building-code=${buildingDetail?.code}`
        );
      }
    }
  };
  const waitlistCheck = eventDetail?.['details']?.['waitlist'] ?? false;
  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  return (
    <div className="description-wrapper h-full">
      {eventDetail && (
        <>
          <div className="first-child md:pt-12">
            <PrimaryLabel
              labelText={`${
                eventDetail?.['details']?.['virtual']
                  ? `${EN.event.ONLINE_EVENT}`
                  : `${EN.event.AT_VENUE}`
              }`}
              className="block text-F16"
            />
            <PrimaryLabel
              labelText={`${eventDetail?.['details']?.['displayName']}`}
              className="sub-header block h-auto event-title font-brandonBold"
            />
            <div className="event-info inline-grid gap-y-4">
              <div className="inline-flex">
                <img
                  src={getImageUrl('ConsumerApp/dayonmodal.svg', true)}
                  alt="day"
                />
                <span className="pl-4 font-brandonMedium text-F17">
                  {eventDetail?.['details']
                    ? getDayAndTime(
                        eventDetail?.['details']?.['startDatetime'],
                        EVENT_DAY_FORMAT,
                        eventDetail?.details?.virtual
                          ? eventDetail?.['details']?.['regionTimezone']
                          : eventDetail?.details?.eventTimezone?.zoneValue
                      )
                    : ''}
                </span>
              </div>
              <div className="inline-flex">
                <img
                  src={getImageUrl('ConsumerApp/Day_timeeventcard.svg', true)}
                  alt="time"
                />
                <span className="pl-4 font-brandonMedium text-F17">
                  {getDayAndTime(
                    eventDetail?.['details']?.['startDatetime'],
                    EVENT_TIME_FORMAT,
                    eventDetail?.details?.virtual
                      ? eventDetail?.['details']?.['regionTimezone']
                      : eventDetail?.details?.eventTimezone?.zoneValue
                  )}{' '}
                  -{' '}
                  {getDayAndTime(
                    eventDetail?.['details']?.['endDatetime'],
                    EVENT_TIME_FORMAT,
                    eventDetail?.details?.virtual
                      ? eventDetail?.['details']?.['regionTimezone']
                      : eventDetail?.details?.eventTimezone?.zoneValue
                  )}{' '}
                  {eventDetail?.details?.virtual
                    ? eventDetail?.['details']?.['regionTimezoneAbbrevation']
                    : eventDetail?.details?.eventTimezone?.shortName}
                </span>
              </div>
              <div className="inline-flex">
                <img
                  src={getImageUrl('ConsumerApp/Venueeventcard.svg', true)}
                  alt="venue"
                />
                <span className="pl-4 font-brandonMedium text-F17">
                  {eventDetail?.['details']?.['virtual']
                    ? `Online - ${eventDetail?.['details']?.['location']}`
                    : eventDetail?.['details']?.['location']}
                </span>
              </div>
              {eventDetail?.['details']?.['virtual'] &&
                checkPastEvent(eventDetail?.['details']?.['startDatetime']) &&
                checkLiveEvent(eventDetail?.['details']?.['endDatetime']) && (
                  <div className="inline-flex">
                    <img
                      src={getImageUrl('ConsumerApp/Events_timer.svg', true)}
                      alt="timer"
                    />
                    <span className="pl-4 font-brandonMedium text-F17">
                      {EN.event.EVENT_LIVE}
                    </span>
                  </div>
                )}
            </div>

            {!eventDetail?.['details']?.['rsvp'] ? (
              <div className="block">
                <Button
                  label={EN.event.BTN_RSVP}
                  isArrowShow={true}
                  className={`btn-iwantrsvp font-brandonBold text-F18 ${
                    checkPastEvent(eventDetail?.['details']?.['endDatetime']) &&
                    'hidden'
                  } ${isLoggedIn && !buildingAccess && 'hidden'}`}
                  type="button"
                  onClick={handleMarkRsvp}
                  buttonText={buttonText}
                  backgroundColor={buttonColor}
                  isWhitelabel={Boolean(isWhitelabel)}
                />
              </div>
            ) : (
              <>
                <hr
                  className={`mt-4 ${
                    !eventDetail?.['details']?.['rsvp'] && 'hidden'
                  }`}
                />
                <div
                  className={`inline-flex ${
                    checkPastEvent(
                      eventDetail?.['details']?.['startDatetime']
                    ) && 'hidden'
                  }`}
                >
                  <img
                    src={
                      waitlistCheck
                        ? getImageUrl('ConsumerApp/waitlisted.svg', true)
                        : getImageUrl('ConsumerApp/blue_tick.svg', true)
                    }
                    alt={`${waitlistCheck ? 'waitlist' : 'tick'}`}
                    className="pr-2 pt-6"
                  />
                  <PrimaryLabel
                    labelText={`${
                      waitlistCheck
                        ? `${EN.event.WAIT_LIST}`
                        : `${EN.event.THANKS_RSVP}`
                    }`}
                    className={`block after-rsvp-${
                      waitlistCheck ? 'waitlist' : 'title'
                    } pt-8`}
                  />

                  <Button
                    label={EN.event.UNRSVP}
                    className="btn-unrsvp hidden md:block"
                    type="button"
                    onClick={handleUnMarkRsvp}
                    buttonText={buttonColor}
                  />
                </div>
                <Button
                  label={EN.event.UNRSVP}
                  className="btn-unrsvp md:hidden float-left"
                  type="button"
                  onClick={handleUnMarkRsvp}
                  buttonText={buttonColor}
                />
              </>
            )}
            <hr
              className={`${
                eventDetail?.['details']?.['rsvp'] ? 'mt-16 md:mt-4' : 'mt-20'
              } ${
                checkPastEvent(eventDetail?.['details']?.['startDatetime']) &&
                checkLiveEvent(eventDetail?.['details']?.['endDatetime']) &&
                'hidden'
              }`}
            />
          </div>
          {eventDetail?.['details']?.['virtual'] &&
            !waitlistCheck &&
            isLoggedIn &&
            eventDetail?.['details']?.['rsvp'] &&
            !checkPastEvent(eventDetail?.['details']?.['endDatetime']) && (
              <div className="block btn-view-event">
                <Button
                  label={`${
                    checkPastEvent(
                      eventDetail?.['details']?.['startDatetime']
                    ) &&
                    checkLiveEvent(eventDetail?.['details']?.['endDatetime'])
                      ? `${EN.event.BEGIN_LIVE}`
                      : `${EN.event.VIEW_EVENT}`
                  }`}
                  isArrowShow={true}
                  className="btn-iwantrsvp font-brandonBold text-F18"
                  type="button"
                  onClick={openLiveStream}
                  buttonText={buttonText}
                  backgroundColor={buttonColor}
                  isWhitelabel={Boolean(isWhitelabel)}
                />
              </div>
            )}
          <div className="event-description">
            <PrimaryLabel
              labelText={EN.event.ABOUT_EVENT}
              className="block sub-header mb-4 about-event-title font-brandonBold"
            />
            <PrimaryLabel
              labelText={eventDetail?.['eventDescription']}
              className="about-sub-header pt-4"
            />
          </div>
          <div className="event-description">
            <PrimaryLabel
              labelText={EN.event.ABOUT_INST}
              className="block sub-header mb-4 about-event-title font-brandonBold"
            />
            <div className="block md:inline-flex">
              {eventDetail?.['instructorDetails']?.['image'] && (
                <img
                  src={getImageUrl(
                    eventDetail?.['instructorDetails']?.['image']
                  )}
                  alt="img"
                  className="block md:hidden w-12 h-12 ml-0 mb-4"
                />
              )}
              <PrimaryLabel
                labelText={
                  eventDetail?.['instructorDetails']?.['description'] ??
                  'No description added!'
                }
                className="inline-flex w-full about-sub-header"
              />
              {eventDetail?.['instructorDetails']?.['image'] && (
                <img
                  src={getImageUrl(
                    eventDetail?.['instructorDetails']?.['image']
                  )}
                  alt="img"
                  className="hidden md:block w-24 h-20 ml-4"
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventDetail;
