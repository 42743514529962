import {
  IContactAdmin,
  IContactAdminResponse,
  IRegisterUser,
  ITermConditionResponse,
} from './../../models/user';
import * as userTypes from './user.constant';
import * as toastTypes from '../toast/toast.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as modalTypes from '../modal/modal.constant';
import {
  contactAdminApi,
  getUserDetailApi,
  updateTermOfServiceApi,
  updateUserDetailApi,
} from './user.service';
import { IUserDetail, IUserDetailResponse } from '../../models';
import { TOAST_MESSAGES } from '../../constants/index';

export const getUserDetail = (buildingName: string) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getUserDetailApi(buildingName);
  if (detail.data) {
    dispatch({
      type: userTypes.GET_USER_DETAILS,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateUserProfile = (
  data: IUserDetail,
  buildingName: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedUser: IUserDetailResponse = await updateUserDetailApi(
    data,
    buildingName
  );
  if (updatedUser.data) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.user.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateRegistrationForm = (data: IRegisterUser) => async (
  dispatch
) => {
  dispatch({
    type: userTypes.UPDATE_REGISTER_FORM,
    payload: data,
  });
};

export const clearRegistrationForm = () => async (dispatch) => {
  dispatch({
    type: userTypes.CLEAR_REGISTER_FORM,
  });
};

export const updateTermOfServices = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updateTerms: ITermConditionResponse = await updateTermOfServiceApi();
  if (updateTerms.data) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    dispatch({
      type: modalTypes.CLOSE_TERM_MODAL,
    });
  }
};

export const contactAdmin = (data: IContactAdmin) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response: IContactAdminResponse = await contactAdminApi(data);
  if (response.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.contact.email,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
