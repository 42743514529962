import { SlideDrawer, TenantTopnav } from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import UserProfile from '../components/Profile/UserProfile';
import { useSelector, useDispatch } from 'react-redux';
import { IUserLoginResponse } from '../models';
import { openModal, openReportModal } from '../redux/modal/modal.action';
import PrivateRoute from './ProtectedRoute';
import LoginPopup from '../components/Authentication/Login';
import { IModalState } from '../redux/modal/modal.state';
import EventListing from '../components/Events/EventListing';
import { BUILDING_NAME, CURRENT_DATE, EN, tenantProfile } from '../constants';
import { clearAuth, verifyPartnerUser } from '../redux/authentication';
import { clearEvents, getEvents } from '../redux/events';
import { IBuldingState, validateBuildingAccess } from '../redux/building';
import { IUserLoginState } from '../redux/authentication';
import LiveStream from '../components/LiveStream/LiveStream';
import ContactUs from '../components/ContactUs/Contact';
import {
  checkBuildingWhiteLabeled,
  getImageUrl,
  getSubDomain,
  removeQueryParameter,
  HOM_LOGO,
  getQueryParameters,
} from '../utils';
import ClusterSelection from '../components/Events/ClusterPage';
import ResetPassword from '../components/Authentication/ResetPassword';
import BadRouteMessage from '../components/Authentication/BadRouteMessage';
import { ProfileMenu, ThemeObjectType } from '../enums';
import GenerateReport from '../components/Reports/GenerateReport';

const Routes: React.FC = () => {
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const { open, opneReport } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const { buildingDetail, isCluster } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const { verifyEmailToken } = useSelector(
    (state: { authentication: IUserLoginState }) => state.authentication
  );
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const accessObj = access && JSON.parse(atob(access.split('.')[1]));
  const idToken = getQueryParameters('idToken');
  const email = getQueryParameters('email');
  const firstName = getQueryParameters('firstName');
  const lastName = getQueryParameters('lastName');

  const openLoginModal = () => {
    dispatch(openModal());
  };

  const onLogout = async () => {
    await dispatch(clearAuth());
    await dispatch(clearEvents());
    await dispatch(getEvents(buildingDetail?.code, CURRENT_DATE, 'upcoming'));
  };

  const contactAdmin = () => {
    setDrawerState(!drawerState);
  };

  const openReport = async () => {
    await dispatch(openReportModal());
  };

  const subDomain = getSubDomain();
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      if (!subDomain) {
        removeQueryParameter('tab');
        push('/error-message');
      }
    }
  }, [push, subDomain]);

  // Used for Rise integration process
  useEffect(() => {
    async function getVerifyIdToken() {
      if (idToken && email) {
        const params = {
          identityToken: idToken,
          email,
          firstName,
          lastName,
        };
        await dispatch(verifyPartnerUser(params));
        await dispatch(clearEvents());
        await dispatch(validateBuildingAccess(subDomain ?? BUILDING_NAME));
        push(`/?tab=${EN.tabs.UPCOMING.toLowerCase()}`);
      }
    }
    getVerifyIdToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  const onMenuClick = async (index: number) => {
    switch (index) {
      case ProfileMenu.PROFILE:
        push('/profile');
        break;
      case ProfileMenu.REPORT:
        if (!accessObj?.['is_pm']) {
          contactAdmin();
          return;
        }
        openReport();
        break;
      case ProfileMenu.CONTACT:
        if (!accessObj?.['is_pm']) {
          await onLogout();
          return;
        }
        contactAdmin();
        break;
      case ProfileMenu.LOG_OUT:
        await onLogout();
        break;
    }
  };

  // Filter options based on Building manager login
  const filterMenuOption = () => {
    if (!accessObj?.['is_pm']) {
      return tenantProfile.filter((option) => option.id !== 2);
    } else {
      return tenantProfile;
    }
  };

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  const routeMatch = useRouteMatch({
    path: '/error-message',
    strict: true,
  });
  const routeMatchLivestream = useRouteMatch({
    path: '/livestream/:eventId',
    strict: true,
  });

  return (
    <>
      {!isCluster && !verifyEmailToken && !routeMatch && !routeMatchLivestream && (
        <div className="fixed top-0 w-full z-50">
          <TenantTopnav
            onLoginClick={openLoginModal}
            isLoggedIn={!access}
            profileOptions={filterMenuOption()}
            onMenuClick={(_, index: number) => onMenuClick(index)}
            onLogoClick={() => push('/?tab=upcoming')}
            isWhiteLabel={Boolean(isWhitelabel)}
            whiteLabelLogo={getImageUrl(
              checkBuildingWhiteLabeled(buildingDetail, ThemeObjectType.LOGO)
            )}
            topBar={checkBuildingWhiteLabeled(
              buildingDetail,
              ThemeObjectType.TOPBAR
            )}
            buttonColor={checkBuildingWhiteLabeled(
              buildingDetail,
              ThemeObjectType.BUTTON_COLOR
            )}
            buttonText={checkBuildingWhiteLabeled(
              buildingDetail,
              ThemeObjectType.BUTTON_TEXT
            )}
            homLogo={HOM_LOGO}
          />
        </div>
      )}
      {open && <LoginPopup modalState={open} />}
      {opneReport && <GenerateReport modalState={opneReport} />}
      <SlideDrawer
        show={drawerState}
        showCloseIcon={true}
        closePane={contactAdmin}
        showIconClass="p-8 md:p-0"
        showIconDivClass="pt-0 pb-0 md:pt-4"
      >
        <ContactUs
          buttonColor={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
          buttonText={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_TEXT
          )}
          closePopup={contactAdmin}
        />
      </SlideDrawer>
      <Switch>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>

        {process.env.REACT_APP_ENV === 'development' ? (
          <Route path="/" exact={true}>
            <EventListing />
          </Route>
        ) : (
          subDomain && (
            <Route path="/" exact={true}>
              <EventListing />
            </Route>
          )
        )}
        <Redirect exact={true} from="/" to="/?tab=upcoming" />
        {/* quick fix because of operation team communication gone wrong with property managers */}
        <Redirect exact={true} from="/allevents" to="/?tab=upcoming" />
        <Redirect exact={true} from="/allevents/" to="/?tab=upcoming" />

        <Route path="/cluster" exact={true}>
          <ClusterSelection />
        </Route>
        <Route path="/error-message" exact={true}>
          <BadRouteMessage />
        </Route>
        <PrivateRoute path="/profile" exact={true}>
          <UserProfile />
        </PrivateRoute>
        <Route path="/livestream/:eventId" exact={true}>
          <LiveStream />
        </Route>
        <Redirect exact={true} from="/*" to="/?tab=upcoming" />
      </Switch>
    </>
  );
};

export default Routes;
