import * as userTypes from './user.constant';
import { IAccountsState, initialAccountState } from './user.state';

export const userReducer = (
  state: IAccountsState = initialAccountState,
  action: { type: string; payload: any }
): IAccountsState => {
  switch (action.type) {
    case userTypes.GET_USER_DETAILS:
      return {
        ...state,
        userDetail: action.payload,
      };
    case userTypes.UPDATE_REGISTER_FORM:
      return {
        ...state,
        userRegistrationForm: action.payload,
      };
    case userTypes.CLEAR_REGISTER_FORM:
      return {
        ...state,
        userRegistrationForm: {},
      };
    default:
      return state;
  }
};
