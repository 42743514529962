import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IBuldingState, validateClusterCode } from '../../redux/building';
import './EventListing.scss';
import { ClusterBuilding, IClusterDetail } from '../../models/building';
import Config from '../../config/environment';
import { getSubDomain } from '../../utils';
import { useDispatch } from 'react-redux';

const ClusterSelection = () => {
  const { register, handleSubmit, setValue } = useForm<IClusterDetail>();
  const [buildingCode, setBuildingCode] = useState<boolean>(false);
  const { clusterDetail, isCluster } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const dispatch = useDispatch();

  const onSubmit = async (data: ClusterBuilding) => {
    window.location.href = `https://${data.buildingCode}.${Config.FRONTEND_DOMAIN}/`;
  };

  useEffect(() => {
    const subDomain = getSubDomain();
    async function validateCluster() {
      if (!isCluster && subDomain) {
        await dispatch(validateClusterCode(subDomain));
      }
    }
    validateCluster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCluster]);

  return (
    <div className="pt-12 p-4 pb-16">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-8 mt-16 cluster-wrapper w-auto md:w-1/3 pt-40">
          <div>
            <PrimaryLabel
              labelText="Select your building"
              className="text-HeaderFont text-center"
            />
            <Dropdown
              asyncDropdown={false}
              labelKey="name"
              valueKey="buildingCode"
              isLabelShow={false}
              label=""
              options={clusterDetail?.['buildings']}
              ref={() => register({ name: 'buildingCode' })}
              onOptionChange={async (e) => {
                setBuildingCode(true);
                setValue('buildingCode', e['buildingCode']);
              }}
              placeholder="Select building"
            />
          </div>
          <div>
            <Button
              label="Click Here"
              className={`w-full block ${
                !buildingCode && 'pointer-events-none bg-Color_6'
              }`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClusterSelection;
