import {
  Button,
  PrimaryLabel,
  StarRating,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import React, { useState } from 'react';
import './EventListing.scss';
import { useSelector, useDispatch } from 'react-redux';
import { eventRating, IEventState } from '../../redux/events';
import { useForm } from 'react-hook-form';
import { IEventRating } from '../../models';
import { EN } from '../../constants';
import { ThemeObjectType } from '../../enums';
import { checkBuildingWhiteLabeled } from '../../utils';
import { IBuldingState } from '../../redux/building';

const EventRating: React.FC<{ eventId: number; closePane: () => void }> = ({
  eventId,
  closePane,
}) => {
  const { register, handleSubmit } = useForm<IEventRating>();
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const { eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );

  const { buildingDetail } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );

  const onSubmit = async (data: IEventRating) => {
    if (eventId) {
      data.reference = 'app';
      data.rating = rating;
      await dispatch(eventRating(data, eventId));
    }
    closePane();
  };

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  return (
    <div className="description-wrapper h-full">
      {eventDetail && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="first-child md:pt-12">
            <PrimaryLabel
              labelText={EN.rating.Title}
              className="sub-header block h-auto event-title"
            />
            <StarRating
              onStarClick={(index: number) => setRating(index)}
              isEdit={false}
              eventRating={rating}
              buttonColor={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_COLOR
              )}
            />
            <div className="pt-8 pb-4">
              <PrimaryLabel labelText={EN.rating.SubTitle} className="block" />
              <TextArea
                label=""
                name="feedback"
                placeholder={EN.rating.Placeholder}
                ref={register}
                className="rating-textarea"
              />
            </div>
            <div className="block">
              <Button
                label={EN.rating.BtnSubmit}
                className={`btn-iwantrsvp ${rating <= 0 && 'btn-disable'}`}
                isWhitelabel={Boolean(isWhitelabel)}
                backgroundColor={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_COLOR
                )}
                buttonText={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_TEXT
                )}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EventRating;
