import {
  IUserLogin,
  IUserLoginResponse,
  IUserRefreshResponse,
  IUserRefreshBody,
  IRegisterUser,
  IForgotPasswordResponse,
  IForgotPasswordDetails,
  IResetPasswordResponse,
  IResetPasswordDetails,
  IVerifyTokenResponse,
  IVerifyTokenDetails,
  IPartnerUser,
} from './../../models/user';
import { authHttp, http } from '../common/http.service';
import { AxiosResponse } from 'axios';

export const loginApi = async (data: IUserLogin): Promise<IUserLoginResponse> =>
  authHttp
    .post(`/tenant-auth/`, data, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IUserLoginResponse>) => {
      return response.data;
    });

export const refreshTokenApi = async (
  data: IUserRefreshBody
): Promise<IUserRefreshResponse> =>
  authHttp
    .post(`/refresh/`, data, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IUserRefreshResponse>) => {
      return response.data;
    });

export const registerUserApi = async (
  data: IRegisterUser,
  buildingName: string
): Promise<IUserLoginResponse> =>
  authHttp
    .post(`/tenant/register/${buildingName}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserLoginResponse>) => {
      return response.data;
    });

export const forgotPasswordApi = async (
  data: IForgotPasswordDetails
): Promise<IForgotPasswordResponse> =>
  http
    .post(`/forgot-password/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IForgotPasswordResponse>) => {
      return response.data;
    });

export const resetPasswordApi = async (
  data: IResetPasswordDetails
): Promise<IResetPasswordResponse> =>
  http
    .post(`/reset-password/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IResetPasswordResponse>) => {
      return response.data;
    });

export const verifyUserTokenApi = async (
  data: IVerifyTokenDetails
): Promise<IVerifyTokenResponse> =>
  http
    .post(`/verify-token/`, data, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IVerifyTokenResponse>) => {
      return response.data;
    });

// Used for Rise integration process
export const verifyPartnerUserApi = async (
  data: IPartnerUser
): Promise<IUserLoginResponse> =>
  authHttp
    .post(`/tenant/partner-token/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserLoginResponse>) => {
      return response.data;
    });
