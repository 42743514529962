import moment from 'moment-timezone';
import {
  EVENT_DATE_FORMAT,
  EVENT__DATE_FILTER_FORMAT,
  RECORDS_LIMIT,
} from '../constants/index';
import { ThemeObjectType } from '../enums';

export const getImageUrl = (path: string, theme: boolean = false) => {
  // if it's theme asset then send theme = true else false
  if (theme) {
    return `${process.env.REACT_APP_CLOUDFRONT_URL}/${path}`;
  }
  return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};

export const apiStatusCheck = (response) => {
  if (response?.['status'] === 200 || response?.['status'] === 201) {
    return true;
  } else {
    return false;
  }
};

export const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('formData', serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const loadState = (formName: string) => {
  try {
    const serializedState = localStorage.getItem('formData');
    if (!serializedState) {
      return {};
    }
    return JSON.parse(serializedState)?.['formData']?.[formName];
  } catch (error) {
    console.error(error);
  }
};

export const clearFormStorage = () => {
  try {
    localStorage.removeItem('formData');
  } catch (error) {
    console.error(error);
  }
};

export const findIndexFromArrayOfObject = (array, key, data) => {
  return array.findIndex((item) => item[key] === data[key]);
};

export const getUploadedImageUrl = (path: string) => {
  return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};

export const splitImageName = (fileName: string) => {
  let uploadedImage;
  if (fileName) {
    uploadedImage = fileName.split('--hom-media--');
  }
  return uploadedImage[1];
};

export const getSerialNumber = (pageNo: number, index: number) => {
  return Number(pageNo * RECORDS_LIMIT) + 1 + index;
};

let idArray = [];
export const getArrayOfIds = (id: number, emptyArray: boolean = false) => {
  const idIndex = idArray.indexOf(id);
  if (emptyArray) {
    idArray = [];
    return idArray;
  }
  if (idIndex !== -1) {
    idArray.splice(idIndex, 1);
    return idArray;
  }
  idArray.push(id);
  return idArray;
};

export const getSubDomain = () => {
  // window.location.host is subdomain.domain.com
  const fullDomainName = window.location.host;
  if (isSubdomain(fullDomainName)) {
    const parts = fullDomainName.split('.');
    const subDomain = parts[0];
    return subDomain;
  } else {
    return null;
  }
};
export const isSubdomain = (url: string) => {
  const regex = new RegExp(/^([a-z]+:\/{2})?([\w-]+\.[\w-]+\.\w+.\w+)$/);
  return !!url.match(regex); // make sure it returns boolean
};

export const getDayAndTime = (
  date: string,
  dateFormat: string,
  timeZone?: string
) => {
  if (date) {
    const startEventTime = timeZone
      ? moment(date).tz(timeZone).format(dateFormat)
      : moment(date).format(dateFormat);
    return startEventTime;
  }
};

export const onTabChange = (tabName: string) => {
  switch (tabName) {
    case 'upcoming':
      return 'upcoming';
    case 'my-rsvps':
      return 'rsvp';
    case 'playlist':
      return 'past';
    default:
      return 'upcoming';
  }
};

export const convertDateToEventDateFormat = (date: Date): string => {
  return moment(date).format(EVENT_DATE_FORMAT);
};
export const convertDateToFilterDateFormat = (date: Date): string => {
  return moment(date).format(EVENT__DATE_FILTER_FORMAT).toLowerCase();
};
export const setQueryParameters = (parameter: string, key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, parameter.toString());
  window.history.replaceState(null, null, '?' + queryParams.toString());
};

export const removeQueryParameter = (key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get(key)) {
    queryParams.delete(key);
  }
  window.history.replaceState(null, null, '?' + queryParams.toString());
};

export const isMobileCheck = () => {
  const isMobile = /iPhone|Android/i.test(navigator.userAgent);
  return isMobile;
};

export const checkPastEvent = (eventTime: string) => {
  return moment().utc().isSameOrAfter(eventTime);
};

export const checkLiveEvent = (eventTime: string) => {
  return moment().utc().isSameOrBefore(eventTime);
};

export const differenceInDays = (eventTime: string) => {
  return moment(eventTime).diff(moment().utc(), 'days');
};

export const getQueryParameters = (key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
};

export const getIdFromVimeoURL = (url: string) => {
  const vimeoId = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url);
  return vimeoId ? vimeoId[3] : null;
};

export const getIdFromYouTubeUrl = (url: string) => {
  const video_id = url.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  );
  return video_id ? video_id[1] : null;
};

export const getFormatedDated = (date: string) => {
  return moment(date).format(EVENT_DATE_FORMAT);
};

export const checkBuildingWhiteLabeled = (buildingDetail, keyName) => {
  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  const whitelabelObj = JSON.parse(localStorage.getItem('whitelabel'));

  switch (keyName) {
    case ThemeObjectType.TOPBAR:
      return isWhitelabel
        ? buildingDetail?.whitelabel?.topBar ?? whitelabelObj?.['topBar']
        : null;
    case ThemeObjectType.TOPBAR_TEXT:
      return isWhitelabel
        ? buildingDetail?.whitelabel?.topBarText ??
            whitelabelObj?.['topBarText']
        : null;
    case ThemeObjectType.LOGO:
      return isWhitelabel
        ? buildingDetail?.whitelabel?.logo ?? whitelabelObj?.['logo']
        : null;
    case ThemeObjectType.BUTTON_TEXT:
      return isWhitelabel
        ? buildingDetail?.whitelabel?.buttonText ??
            whitelabelObj?.['buttonText']
        : null;
    case ThemeObjectType.BUTTON_COLOR:
      return isWhitelabel
        ? buildingDetail?.whitelabel?.buttonColor ??
            whitelabelObj?.['buttonColor']
        : null;
  }
};

export const HOM_LOGO = getImageUrl('ConsumerApp/LogoHOM.svg', true);

export const getDateFormated = (date: string, format: string) => {
  return moment(date).format(format);
};
