import { IRegisterUser } from './../../models/user';
import { IUserDetail } from '../../models';

export interface IAccountsState {
  userDetail: IUserDetail;
  userRegistrationForm: IRegisterUser;
}

export const initialAccountState: IAccountsState = {
  userDetail: {},
  userRegistrationForm: {},
};
