import { initialBuildingState, IBuldingState } from './building.state';
import * as buildingTypes from './building.constant';

export const buildingReducer = (
  state: IBuldingState = initialBuildingState,
  action: { type: string; payload: any; code: string }
): IBuldingState => {
  switch (action.type) {
    case buildingTypes.VALIDATE_BUILDING:
      localStorage.setItem('whitelabelActive', action.payload.whitelabelActive);
      localStorage.setItem(
        'whitelabel',
        JSON.stringify(action.payload.whitelabel)
      );
      return {
        ...state,
        buildingDetail: { ...action.payload, code: action.code },
        fetchedBuildingDetail: true,
        isCluster: false,
      };
    case buildingTypes.GET_BUILDING_BLOCKS:
      return {
        ...state,
        buildingUnit: action.payload,
      };
    case buildingTypes.VALIDATE_CLUSTER:
      return {
        ...state,
        clusterDetail: { ...action.payload },
        isCluster: true,
      };
    case buildingTypes.VALIDATE_BUILDING_ACCESS:
      localStorage.setItem('buildingAccess', action.payload);
      return {
        ...state,
        buildingAccess: action.payload,
      };
    case buildingTypes.GENERATE_CLIENT_REPORT:
      return {
        ...state,
        clientReport: action.payload,
      };
    case buildingTypes.CLEAR_CLIENT_REPORT:
      return {
        ...state,
        clientReport: {},
      };
    default:
      return state;
  }
};
