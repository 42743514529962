import { buildingReducer } from './building/building.reducer';
import { modalReducer } from './modal/modal.reducer';
import { eventReducer } from './events/events.reducer';
import { combineReducers, Reducer } from 'redux';
import { IAppState } from './app.state';
import { authReducer } from './authentication/authentication.reducer';
import { userReducer } from './user/user.reducer';
import { toastReducer } from './toast';
import { loadingReducer } from './loader';

export const rootReducer: Reducer<IAppState> = combineReducers({
  authentication: authReducer,
  events: eventReducer,
  modal: modalReducer,
  user: userReducer,
  toast: toastReducer,
  building: buildingReducer,
  loading: loadingReducer,
});
