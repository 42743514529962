import React from 'react';
import { Loader } from '@alfredclub/hom-planner-theme';

const CommonLoader: React.FC<{
  loading: boolean;
  color?: string;
  className?: string;
}> = ({ loading, color, className }) => {
  return (
    <Loader
      color={color ?? '#f36b7f'}
      position="center"
      loading={loading}
      size="0.75rem"
      className={className}
    />
  );
};
export default CommonLoader;
