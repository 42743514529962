import React, { useRef } from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { getImageUrl, HOM_LOGO } from '../../utils';
import { IRegisterUser } from '../../models';
import { useDispatch } from 'react-redux';
import { updateRegistrationForm } from '../../redux/user';

const StepOne: React.FC<{
  nextStep: () => void;
  buttonText: string | null;
  buttonColor: string | null;
  logo: string | null;
}> = ({ nextStep, buttonText, buttonColor, logo }) => {
  const { register, handleSubmit, errors, watch } = useForm<IRegisterUser>();
  const password = useRef<string>(null);
  password.current = watch('password', '');
  const dispatch = useDispatch();
  const onSubmit = async (data: IRegisterUser) => {
    data['email'] = data.email.trim().toLowerCase();
    await dispatch(updateRegistrationForm(data));
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 overflow-x-hidden overflow-y-scroll md:overflow-hidden signup-wrapper mb-8 md:mb-0">
        <img
          src={logo ? getImageUrl(logo) : HOM_LOGO}
          className="max-h-1/4"
          alt="logo"
        />
        <PrimaryLabel
          labelText="Create My Profile"
          className="sub-header signup-title"
        />
        <div className="pt-16">
          <PrimaryLabel labelText="Email Address" />
          <Input
            label=""
            name="email"
            inputType="email"
            isError={errors?.['email'] ? true : false}
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
            placeholder="Enter email address"
          />
        </div>
        <div className="pt-8">
          <PrimaryLabel labelText="Password" />
          <Input
            label=""
            placeholder="Enter password"
            name="password"
            inputType="password"
            ref={register({ required: true })}
            isError={errors?.['password'] ? true : false}
          />
        </div>
        <div className="pt-8">
          <PrimaryLabel labelText="Re-enter password" />
          <Input
            label=""
            placeholder="Re-enter password"
            name="repassword"
            inputType="password"
            ref={register({
              validate: (value) =>
                value === password.current || 'The passwords do not match',
            })}
            isError={errors?.['repassword'] ? true : false}
          />
          {errors?.['repassword'] && <p>{errors?.['repassword'].message}</p>}
        </div>

        <Button
          label="Next"
          isArrowShow={true}
          className="w-full md:w-auto btn-next mt-10"
          backgroundColor={buttonColor}
          buttonText={buttonText}
        />
        <PrimaryLabel
          labelText="Step 1/2"
          className="inline-grid justify-center pt-2 pb-12"
        />
      </div>
    </form>
  );
};
export default StepOne;
