import iZtoast from 'izitoast';
import { TOAST_POSITION, TOAST_PROGRESS_BAR } from '../../constants/index';

const toast = {
  error: (message: string, title: string = 'Error') => {
    return iZtoast.error({
      progressBar: TOAST_PROGRESS_BAR,
      position: TOAST_POSITION,
      message,
      title,
    });
  },
  success: (message: string, title: string = 'Success') => {
    return iZtoast.success({
      progressBar: TOAST_PROGRESS_BAR,
      position: TOAST_POSITION,
      message,
      title,
    });
  },
  info: (message: string, title: string = 'Info') => {
    return iZtoast.info({
      progressBar: TOAST_PROGRESS_BAR,
      position: TOAST_POSITION,
      message,
      title,
    });
  },
};

export default toast;
