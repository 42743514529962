import * as modalTypes from './modal.constant';

export const openModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_MODAL,
  });
};

export const openRegistraionModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_REGISTRATION,
  });
};

export const openUnrsvpModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_UNRSVP,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.CLOSE_MODAL,
  });
};

export const openForgotPasswordModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_FORGOT_PASSWORD,
  });
};

export const openNoAccessModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_NO_ACCESS,
  });
};

export const openBlockRegistrationModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.BLOCK_REGISTRATION,
  });
};

export const closeBlockRegistrationModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.CLOSE_BLOCK_REGISTRATION,
  });
};

export const openReportModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_REPORT,
  });
};
