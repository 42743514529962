import React, { useEffect, useState } from 'react';
import { Calendar, Tabs } from '@alfredclub/hom-planner-theme';
import UpcomingList from './UpcomingList';
import { IUserLoginResponse } from '../../models';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../../redux/modal/modal.action';
import LoginPopup from '../Authentication/Login';
import ForgotPassword from '../Authentication/ForgotPassword';
import { IModalState } from '../../redux/modal/modal.state';
import {
  checkBuildingWhiteLabeled,
  convertDateToEventDateFormat,
  convertDateToFilterDateFormat,
  getFormatedDated,
  getQueryParameters,
  getSubDomain,
  onTabChange,
  setQueryParameters,
} from '../../utils';
import {
  validateBuildingAccess,
  validateBuildingCode,
} from '../../redux/building';
import Signup from '../Signup/Signup';
import {
  clearEvents,
  getEventDateCount,
  getEvents,
  getFilteredEvents,
  IEventState,
} from '../../redux/events';
import { IBuldingState } from '../../redux/building';
import {
  currentMonth,
  CURRENT_DATE,
  EN,
  EVENT_DATE_FORMAT,
  PAST_DATE,
} from '../../constants';
import LoginMessage from './LoginMessage';
import { ICalendarObject } from '../../models/calendar';
import TermCondition from '../Signup/TermCondition';
import './EventListing.scss';
import { ThemeObjectType } from '../../enums';
import BlockRegistration from '../Common/BlockRegistration';
import moment from 'moment-timezone';

const EventListing = () => {
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const {
    open,
    openRegistration,
    openTermCondition,
    openForgotPassword,
    blockRegistration,
  } = useSelector((state: { modal: IModalState }) => state.modal);
  const { events, eventDateCount, eventCount } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { buildingDetail, fetchedBuildingDetail, buildingAccess } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const [tab, setTab] = useState<string>(EN.tabs.UPCOMING.toLowerCase());
  const [filterDate, setFilterDate] = useState<string | null>(null);
  const [monthDate, setMonthDate] = useState<string>(CURRENT_DATE);
  const [isFetching, setIsFetching] = useState(false);
  const [pageNo, setPage] = useState(1);
  const dispatch = useDispatch();
  const openLoginModal = () => {
    dispatch(openModal());
  };
  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }
    setIsFetching(true);
  };
  let timer = null;
  const fetchData = async () => {
    if (events.length < eventCount) {
      clearTimeout(timer);
      return (timer = setTimeout(async () => {
        !filterDate
          ? await getEvent(getQueryParameters('tab'), pageNo)
          : await dispatch(
              getFilteredEvents(
                buildingDetail.code,
                moment(filterDate).format(EVENT_DATE_FORMAT),
                EN.tabs.UPCOMING.toLowerCase(),
                pageNo
              )
            );
        setPage(pageNo + 1);
        setIsFetching(false);
      }, 1000));
    }
  };
  const fetchMoreListItems = () => {
    fetchData();
  };

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    fetchMoreListItems();
    // eslint-disable-next-line
  }, [isFetching]);
  const onMonthChange = async (data: ICalendarObject) => {
    setFilterDate(null);
    let date = null;
    if (currentMonth === data.month + 1) {
      date = CURRENT_DATE;
    } else {
      date = getFormatedDated(`${data.year}-${data.month + 1}-01`);
    }
    setMonthDate(() => date);
    setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
    await dispatch(clearEvents());
    await dispatch(
      getEvents(buildingDetail.code, date, EN.tabs.UPCOMING.toLowerCase())
    );
    await dispatch(getEventDateCount(buildingDetail.code, date));
  };
  const getEvent = async (tabName: string, page?: number) => {
    await dispatch(
      getEvents(
        buildingDetail.code,
        tabName === EN.tabs.PLAYLIST.toLowerCase() ? PAST_DATE : monthDate,
        onTabChange(tabName),
        page
      )
    );
  };

  useEffect(() => {
    const subDomain = getSubDomain();
    async function validateBuilding() {
      if (!fetchedBuildingDetail && subDomain) {
        await dispatch(
          validateBuildingCode(subDomain, CURRENT_DATE, onTabChange(tab))
        );
        setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
      } else {
        await dispatch(clearEvents());
        await getEvent(onTabChange(tab));
        setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
        // if user has no building permission but later from planner side user's building
        // permission is updated then it will show message accordingly.
        if (access && !buildingAccess) {
          await dispatch(
            validateBuildingAccess(subDomain ?? buildingDetail.code)
          );
        }
      }
    }
    validateBuilding();
    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedBuildingDetail]);

  const filteredEvents = async (date: Date | null) => {
    if (date) {
      const filter_date = convertDateToEventDateFormat(date);
      const filter_event_date_format = convertDateToFilterDateFormat(date);
      await dispatch(clearEvents());
      const filterEventCount = await dispatch(
        getFilteredEvents(
          buildingDetail.code,
          filter_date,
          EN.tabs.UPCOMING.toLowerCase()
        )
      );
      setQueryParameters(
        `${filterEventCount} ${EN.event.EVENTS_ON.toLowerCase()} ${filter_event_date_format}`,
        'tab'
      );
      setFilterDate(filter_event_date_format);
      setIsFetching(false);
      setPage(1);
      return;
    }
    setFilterDate(null);
    setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
    await dispatch(clearEvents());
    setIsFetching(false);
    setPage(1);
    await getEvent(EN.tabs.UPCOMING.toLowerCase());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');
  const isBuildingAccess =
    buildingAccess ?? localStorage.getItem('buildingAccess');

  return (
    <>
      {openRegistration && (
        <Signup
          modalState={openRegistration}
          buttonText={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_TEXT
          )}
          buttonColor={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
          logo={checkBuildingWhiteLabeled(buildingDetail, ThemeObjectType.LOGO)}
        />
      )}
      {openForgotPassword && (
        <ForgotPassword
          modalState={openForgotPassword}
          logo={checkBuildingWhiteLabeled(buildingDetail, ThemeObjectType.LOGO)}
          buttonText={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_TEXT
          )}
          buttonColor={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
        />
      )}
      {open && <LoginPopup modalState={open} />}
      {openTermCondition && <TermCondition modalState={openTermCondition} />}
      {blockRegistration && (
        <BlockRegistration modalState={blockRegistration} />
      )}
      <div className="md:container md:mx-auto mt-20 p-4 md:p-0 listing-wrapper">
        <Calendar
          isToggleCalendar={false}
          selectedClass="date-selected"
          hoverClass="date-hover"
          showDots={true}
          eventCount={eventDateCount}
          onMonthOrYearChange={(e: ICalendarObject) => onMonthChange(e)}
          setCalendarDate={(e) => filteredEvents(e)}
          className={`${
            !isBuildingAccess && !!access ? 'pointer-events-none' : ''
          }`}
        />
        <Tabs
          className="mt-8 mb-8"
          onTabClick={async (tabName: string) => {
            if (access) {
              await dispatch(clearEvents());
            }
            if (tabName === EN.tabs.UPCOMING) {
              await getEvent(tabName);
            } else if (access && tabName) {
              await getEvent(tabName);
            }
            setTab(tabName);
            setPage(1);
            setIsFetching(false);
          }}
          buttonColor={
            checkBuildingWhiteLabeled(
              buildingDetail,
              ThemeObjectType.BUTTON_COLOR
            ) ?? '#f36b7f'
          }
          imgClass={`tab-icon ${filterDate ? 'hidden' : ''}`}
          isWhiteLabel={Boolean(isWhitelabel)}
          activeTabClass={`${filterDate ? `text-F20 font-brandonBold` : ``}`}
        >
          <div
            data-label={
              filterDate
                ? `${eventCount} ${
                    EN.event.EVENTS_ON
                  } ${filterDate.toUpperCase()}`
                : EN.tabs.UPCOMING
            }
            data-click={true}
          >
            <UpcomingList
              isLoggedIn={!!access}
              openLoginModal={openLoginModal}
              tabName={tab}
              filterDate={filterDate}
            />
          </div>
          {!filterDate && (
            <div data-label={EN.tabs.MY_RSVP} data-click={true}>
              {access ? (
                <UpcomingList
                  isLoggedIn={!!access}
                  openLoginModal={openLoginModal}
                  tabName={tab}
                />
              ) : (
                <LoginMessage
                  openLoginModal={openLoginModal}
                  tab={tab}
                  buttonText={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_TEXT
                  )}
                  buttonColor={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_COLOR
                  )}
                />
              )}
            </div>
          )}

          {!filterDate && (
            <div data-label={EN.tabs.PLAYLIST} data-click={true}>
              {access ? (
                <UpcomingList
                  isLoggedIn={!!access}
                  openLoginModal={openLoginModal}
                  tabName={tab}
                />
              ) : (
                <LoginMessage
                  openLoginModal={openLoginModal}
                  tab={tab}
                  buttonText={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_TEXT
                  )}
                  buttonColor={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_COLOR
                  )}
                />
              )}
            </div>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default EventListing;
