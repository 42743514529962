import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/modal/modal.action';
import { getImageUrl } from '../../utils';
import { EN } from '../../constants';

const NoAccessPopup: React.FC<{ modalState: boolean }> = ({ modalState }) => {
  const dispatch = useDispatch();
  const close = async () => {
    await dispatch(closeModal());
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="no-access h-auto w-mobileMenu md:w-auto rounded-lg transform translate-y-40"
      shouldCloseOnOverlayClick={false}
      closebtnClass="top-6 right-6"
    >
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 text-center">
        <img src={getImageUrl('ConsumerApp/No-access.svg', true)} alt="icon" />
        <PrimaryLabel
          labelText={EN.noAccess.Title}
          className="title text-Color_22"
        />
        <PrimaryLabel
          labelText={EN.noAccess.SubTitle}
          className="sub-title text-Color_22 md:w-mobileMenu margin-auto"
        />
      </div>
    </ModalPopup>
  );
};

export default NoAccessPopup;
