import React from 'react';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../utils';
import { EN } from '../../constants/index';
import './Login.scss';

const BadRouteMessage = () => {
  const { REACT_APP_ENV } = process.env;

  return (
    <div className="flex flex-col items-center px-5 pt-20 md:pt-32">
      <img src={getImageUrl('Vendor/hom.events.svg', true)} alt="logo" />
      <div className="pt-10">
        <PrimaryLabel
          labelText={
            REACT_APP_ENV === 'staging'
              ? EN.homEvent.staging_heading
              : EN.homEvent.prod_heading
          }
          className="text-2xl font-medium font-brandonMedium text-Color_22 text-center flex"
        />
      </div>
      <div className="items-center flex flex-col text-center">
        <PrimaryLabel
          labelText={EN.homEvent.subHeading}
          className="text-Color_22 font-brandonRegular text-xl"
        />
        <PrimaryLabel
          labelText={
            REACT_APP_ENV === 'staging'
              ? EN.homEvent.staging_example
              : EN.homEvent.prod_example
          }
          className="text-Color_22 font-brandonRegular text-xl"
        />
      </div>
      <div className="items-center flex flex-col text-center pt-16">
        <PrimaryLabel
          labelText={EN.homEvent.message}
          className="font-brandonRegular text-F17"
        />
      </div>
    </div>
  );
};

export default BadRouteMessage;
