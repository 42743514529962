import { initialModalState, IModalState } from './modal.state';
import * as modalTypes from './modal.constant';

export const modalReducer = (
  state: IModalState = initialModalState,
  action: { type: string }
): IModalState => {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {
        ...state,
        open: true,
      };
    case modalTypes.OPEN_REGISTRATION:
      return {
        ...state,
        openRegistration: true,
      };
    case modalTypes.OPEN_UNRSVP:
      return {
        ...state,
        openUnrsvp: true,
      };
    case modalTypes.OPEN_TERM_CONDITION:
      return {
        ...state,
        openTermCondition: true,
      };
    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        openRegistration: false,
        openForgotPassword: false,
        openUnrsvp: false,
        openNoAccess: false,
        opneReport: false,
      };
    case modalTypes.CLOSE_TERM_MODAL:
      return {
        ...state,
        openTermCondition: false,
      };
    case modalTypes.OPEN_FORGOT_PASSWORD:
      return {
        ...state,
        openForgotPassword: true,
      };
    case modalTypes.OPEN_NO_ACCESS:
      return {
        ...state,
        openNoAccess: true,
      };
    case modalTypes.BLOCK_REGISTRATION:
      return {
        ...state,
        blockRegistration: true,
      };
    case modalTypes.CLOSE_BLOCK_REGISTRATION:
      return {
        ...state,
        blockRegistration: false,
      };
    case modalTypes.OPEN_REPORT:
      return {
        ...state,
        opneReport: true,
      };
    default:
      return state;
  }
};
