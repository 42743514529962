import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export const useFetch = (action, actionArguments?) => {
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch();
  const dependecies = [dispatch, action];
  useEffect(() => {
    const fetchData = async () => {
      setStatus('fetching');
      if (actionArguments) {
        await dispatch(action(...actionArguments));
        setStatus('fetched');
        return;
      }
      await dispatch(action());
      setStatus('fetched');
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependecies);

  return { status };
};
