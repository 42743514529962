import React from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import ModalPopup from '../ReactModal/Modal';
import { IForgotPasswordDetails } from '../../models';
import { getImageUrl, getSubDomain, HOM_LOGO } from '../../utils';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/authentication';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import { EN, BUILDING_NAME } from '../../constants/index';
import { ButtonType } from '../../enums';
import { closeModal } from '../../redux/modal/modal.action';
import './Login.scss';

const ForgotPassword: React.FC<{
  modalState: boolean;
  logo: string | null;
  buttonText: string | null;
  buttonColor: string | null;
}> = ({ modalState, logo, buttonColor, buttonText }) => {
  const dispatch = useDispatch();
  const subDomain = getSubDomain();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { handleSubmit, register, errors } = useForm<IForgotPasswordDetails>();
  const onSubmit = async (data: IForgotPasswordDetails) => {
    // data['building_code'] = subDomain ?? BUILDING_NAME;
    if (data.email.trim().toLowerCase().length > 0) {
      const emailObj = {
        email: data.email.trim().toLowerCase(),
        buildingCode: subDomain ?? BUILDING_NAME,
      };
      await dispatch(forgotPassword(emailObj));
    }
    await dispatch(closeModal());
  };
  const close = async () => {
    await dispatch(closeModal());
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="login-modal h-full md:h-auto rounded-none md:rounded-lg transform translate-y-0 md:translate-y-8"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <form className="w-full mt-7" onSubmit={handleSubmit(onSubmit)}>
        <img
          src={logo ? getImageUrl(logo) : HOM_LOGO}
          alt="logo"
          className="max-h-1/4"
        />
        <div className="mt-12">
          <PrimaryLabel
            className="font-bold font-brandonBold forgot_heading text-Color_22"
            labelText={EN.login.FORGOT_YOUR_PASSWORD}
          />
        </div>
        <div className="mt-6">
          <PrimaryLabel
            className="text-F17 font-medium font-brandonMedium text-Color_22"
            labelText={EN.login.FORGOT_PASSWORD_STATEMENT_1}
          />
        </div>
        <div className="">
          <PrimaryLabel
            className="text-F17 font-brandonRegular text-Color_22"
            labelText={EN.login.FORGOT_PASSWORD_STATEMENT_2}
          />
        </div>
        <div className="mt-5">
          <div>
            <PrimaryLabel labelText={EN.login.EMAIL_ADDRESS} />
            <Input
              label=""
              name="email"
              inputType="text"
              isError={errors?.email ? true : false}
              ref={register({
                required: true,
              })}
            />
          </div>
          <div className="mt-8 pb-20">
            <Button
              label={EN.login.RESET_PASSWORD}
              className={`block w-full md:w-full ${
                loading && 'button_disable'
              }`}
              type={ButtonType.SUBMIT}
              backgroundColor={buttonColor ?? '#f36b7f'}
              buttonText={buttonText ?? '#fefefe'}
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </ModalPopup>
  );
};

export default ForgotPassword;
