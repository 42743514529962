import { IUpdateMessage } from './../../models/chat';
import { IChatToken, IChatTokenResponse } from './../../models';
import { AxiosResponse } from 'axios';
import { http } from '../common/http.service';

export const getChatTokenApi = (): Promise<IChatToken> => {
  const userName = localStorage.getItem('chatUserName');
  return http
    .get(`livestream/chat?${userName ? `identity=${userName}` : ''}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IChatTokenResponse>) => {
      return response.data.data;
    });
};

export const updateChatMessageApi = (data: IUpdateMessage) => {
  return http
    .put(`livestream/chat`, data, { errorHandler: true })
    .then((response) => {
      return response.data?.['data'];
    });
};
