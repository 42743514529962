import React from 'react';
import { Button } from '@alfredclub/hom-planner-theme';
import './EventListing.scss';

const LoginMessage: React.FC<{
  openLoginModal: () => void;
  tab: string;
  buttonText: string | null;
  buttonColor: string | null;
}> = ({ openLoginModal, tab, buttonText, buttonColor }) => {
  return (
    <div className="text-center no-event-wrapper">
      <p className="col-span-2 text-center text-MobileFont md:text-EventHeader p-4 text-Color_22">
        {tab === 'my-rsvps'
          ? 'Please login to see your RSVPs'
          : 'Please login to view the playlist'}
      </p>
      <Button
        label="Log In"
        type="button"
        onClick={openLoginModal}
        className="tab-btn-login"
        backgroundColor={buttonColor}
        buttonText={buttonText}
      />
    </div>
  );
};

export default LoginMessage;
