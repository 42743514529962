import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { useForm } from 'react-hook-form';
import { contactAdmin } from '../../redux/user';
import { useDispatch } from 'react-redux';
import './Contact.scss';
import { IContactAdmin } from '../../models';

const ContactUs: React.FC<{
  buttonColor?: string | null;
  buttonText?: string | null;
  closePopup: () => void;
}> = ({ buttonColor, buttonText, closePopup }) => {
  const { register, handleSubmit } = useForm<IContactAdmin>();
  const dispatch = useDispatch();

  const onSubmit = async (data: IContactAdmin) => {
    await dispatch(contactAdmin(data));
    closePopup();
  };

  return (
    <div className="contact-section p-12 pt-0 md:pt-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PrimaryLabel
          labelText="Hey, fellow HOMie!"
          className="sub-header contact-title block mb-4"
        />
        <PrimaryLabel
          labelText="Type in your message below and we’ll get back to you asap!"
          className="mb-4"
        />
        <TextArea
          label=""
          name="message"
          placeholder="Type your message here"
          ref={register({ required: true })}
          className="contact-textarea mt-4 mb-4"
        />
        <div className="block">
          <Button
            label="Send message"
            isArrowShow={true}
            className="btn-send"
            backgroundColor={buttonColor}
            buttonText={buttonText}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
