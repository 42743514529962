import { IRegisterUser, IPartnerUser } from './../../models/user';
import {
  loginApi,
  registerUserApi,
  forgotPasswordApi,
  resetPasswordApi,
  verifyUserTokenApi,
  verifyPartnerUserApi,
} from './authentication.service';
import * as toastTypes from '../toast/toast.constant';
import * as authTypes from './authentication.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as modalTypes from '../modal/modal.constant';
import {
  IUserLogin,
  IForgotPasswordDetails,
  IResetPasswordDetails,
  IVerifyTokenDetails,
} from '../../models/user';
import Config from '../../config/environment';

export const login = (data: IUserLogin) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const authResponse = await loginApi(data);
  if (authResponse?.['data']) {
    dispatch({
      type: authTypes.AUTH_LOGIN,
      payload: authResponse?.['data'],
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearAuth = () => async (dispatch) => {
  await localStorage.clear();
  dispatch({
    type: authTypes.CLEAR_TOKEN,
  });
};

export const refreshToken = (data) => {
  return {
    type: authTypes.UPDATE_REFRESH_TOKEN,
    payload: data,
  };
};

export const updateTimeZone = (data) => {
  return {
    type: authTypes.UPDATE_USER_TIME_ZONE,
    payload: data,
  };
};

export const registerUser = (
  data: IRegisterUser,
  buildingName: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const registeredUser = await registerUserApi(data, buildingName);
  if (registeredUser?.['data']) {
    dispatch({
      type: authTypes.AUTH_LOGIN,
      payload: registeredUser?.['data'],
    });
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: registeredUser?.['data']?.['message'],
    });
    dispatch({
      type: modalTypes.OPEN_TERM_CONDITION,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const forgotPassword = (data: IForgotPasswordDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await forgotPasswordApi(data);
  if (response?.['data']) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: response.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const resetPassword = (data: IResetPasswordDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await resetPasswordApi(data);
  if (response?.['data']) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: response.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const verifyUserToken = (data: IVerifyTokenDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await verifyUserTokenApi(data);
  if (response?.['data']) {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
  } else if (response?.['error']?.['code'] === 400) {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
  } else {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const verifyEmailToken = (check: boolean) => async (dispatch) => {
  dispatch({
    payload: check,
    type: authTypes.VERIFY_EMAIL_TOKEN,
  });
};

// Used for Rise integration process
export const verifyPartnerUser = (data: IPartnerUser) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const verifyUser = await verifyPartnerUserApi(data);
  if (verifyUser?.['data']) {
    dispatch({
      type: authTypes.AUTH_LOGIN,
      payload: verifyUser?.['data'],
    });
  } else if (
    verifyUser?.['error']?.['code'] !== 401 &&
    verifyUser?.['error']?.['code'] !== 404
  ) {
    window.location.href = `https://${Config.FRONTEND_DOMAIN}/error-message`;
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};
