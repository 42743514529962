import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
} from '@alfredclub/hom-planner-theme';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IRegisterUser } from '../../models';
import { getImageUrl, HOM_LOGO } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { IAccountsState, updateRegistrationForm } from '../../redux/user';
import { registerUser } from '../../redux/authentication';
import {
  getBuildingBlocks,
  IBuldingState,
  validateBuildingAccess,
} from '../../redux/building';
import { ILoaderState } from '../../redux/loader';
const StepTwo: React.FC<{
  closeModal: () => void;
  buttonText: string | null;
  buttonColor: string | null;
  logo: string | null;
}> = ({ closeModal, buttonText, buttonColor, logo }) => {
  const { register, handleSubmit, setValue } = useForm<IRegisterUser>({
    defaultValues: {
      firstName: '',
      lastName: '',
      unit: { id: null },
      marketingEmails: false,
      newsDigest: false,
    },
  });
  const { userRegistrationForm } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const { buildingDetail, buildingUnit } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const dispatch = useDispatch();
  const onSubmit = async (data: IRegisterUser) => {
    await dispatch(updateRegistrationForm(data));
    await dispatch(
      registerUser({ ...userRegistrationForm, ...data }, buildingDetail.code)
    );
    await dispatch(validateBuildingAccess(buildingDetail.code));
    await closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-8">
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 overflow-x-hidden overflow-y-scroll md:overflow-hidden signup-wrapper mb-8 md:mb-0 pb-12">
        <img
          src={logo ? getImageUrl(logo) : HOM_LOGO}
          className="max-h-1/4"
          alt="logo"
        />
        <PrimaryLabel
          labelText="Just a few more details.."
          className="sub-header signup-title"
        />
        <div className="pt-16">
          <PrimaryLabel labelText="First Name" />
          <Input
            label=""
            name="firstName"
            ref={register()}
            placeholder="Enter first name"
          />
        </div>
        <div className="pt-8">
          <PrimaryLabel labelText="Last Name" />
          <Input
            label=""
            name="lastName"
            ref={register()}
            placeholder="Enter last name"
          />
        </div>
        <div className="pt-8">
          <PrimaryLabel
            labelText={`Select ${
              buildingDetail?.commercial ? 'Company' : 'Unit'
            }`}
          />
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={false}
            label=""
            options={buildingUnit}
            onMenuOpen={async () => {
              if (buildingUnit.length === 0) {
                await dispatch(getBuildingBlocks(buildingDetail.code));
              }
            }}
            loading={loading}
            ref={() => register({ name: 'unit' })}
            onOptionChange={async (e) => {
              const unitObj = {
                id: e['id'],
              };
              setValue('unit', unitObj);
            }}
            placeholder={`Select ${
              buildingDetail?.commercial ? 'company' : 'unit'
            }`}
          />
        </div>
        <div className="pt-8">
          <div className="md:col-span-2 flex pb-2">
            <input
              type="checkbox"
              className="checkbox"
              name="newsDigest"
              ref={register}
            />
            <span>Subscribe to weekly upcoming event digest</span>
          </div>
          <div className="md:col-span-2 flex">
            <input
              type="checkbox"
              className="checkbox"
              name="marketingEmails"
              ref={register}
            />
            <span>
              I consent to receive marketing emails about your services
            </span>
          </div>
        </div>

        <Button
          label="Create Account"
          isArrowShow={true}
          className="w-full md:w-auto btn-next mt-10"
          backgroundColor={buttonColor}
          buttonText={buttonText}
        />
        <PrimaryLabel
          labelText="Step 2/2"
          className="inline-grid justify-center pt-2 pb-12"
        />
      </div>
    </form>
  );
};
export default StepTwo;
