import React, { useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/modal/modal.action';
import './Signup.scss';
import { clearRegistrationForm } from '../../redux/user';

const Signup: React.FC<{
  modalState: boolean;
  buttonText: string | null;
  buttonColor: string | null;
  logo: string | null;
}> = ({ modalState, buttonText, buttonColor, logo }) => {
  const [state, setState] = useState<number>(1);
  const dispatch = useDispatch();
  const close = async () => {
    await dispatch(clearRegistrationForm());
    await dispatch(closeModal());
  };
  const nextStep = () => {
    setState(2);
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="signup-modal h-full md:h-auto rounded-none md:rounded-lg transform translate-y-0 md:translate-y-8 md:pb-0"
      shouldCloseOnOverlayClick={false}
    >
      {state === 1 ? (
        <StepOne
          nextStep={nextStep}
          buttonText={buttonText}
          buttonColor={buttonColor}
          logo={logo}
        />
      ) : (
        <StepTwo
          closeModal={close}
          buttonText={buttonText}
          buttonColor={buttonColor}
          logo={logo}
        />
      )}
    </ModalPopup>
  );
};

export default Signup;
