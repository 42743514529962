// tslint:disable: semicolon
import React, { Component } from 'react';
import Message from './Message';
import { IChatMessageProps } from '../../../models';

class MessageList extends Component<IChatMessageProps> {
  messageListWrapper: React.RefObject<HTMLDivElement>;
  constructor(props: IChatMessageProps) {
    super(props);
    this.messageListWrapper = React.createRef();
  }
  componentDidUpdate = () => {
    this.messageListWrapper.current.scrollTop = this.messageListWrapper.current.scrollHeight;
  };

  render() {
    const { teacher, teacherName, onUpdateMessage, username } = this.props;
    return (
      <div className="MessageList mt-4 mb-4" ref={this.messageListWrapper}>
        {this.props.messages.map((message, i) => (
          <Message
            key={i}
            message={message}
            username={username}
            isTeacher={teacher}
            onUpdateMessage={onUpdateMessage}
            teacherName={teacherName}
          />
        ))}
      </div>
    );
  }
}

export default MessageList;
