import { IVerifyToken } from './../../models/user';
export interface IUserLoginState {
  access: string;
  refresh: string;
  verifyToken: IVerifyToken;
  verifyEmailToken: boolean;
}

export const initialAuthState: IUserLoginState = {
  access: localStorage.getItem('access') ?? null,
  refresh: localStorage.getItem('refresh') ?? null,
  verifyToken: {
    data: {
      message: '',
      code: 0,
    },
    error: {
      message: '',
      code: 0,
    },
  },
  verifyEmailToken: false,
};
