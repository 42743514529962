import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './root.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export const configureStore = () => {
  const reduxStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return reduxStore;
};

export const store = configureStore();
