const development = {
  API: 'https://consumer-api-dev.hom.events',
  URL: 'https://kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  FRONTEND_DOMAIN: 'kadbu.com',
};

const production = {
  API: 'https://consumer-api.helloalfred.com',
  URL: 'https://events.helloalfred.com',
  AUTH_API: 'https://auth-api.helloalfred.com',
  FRONTEND_DOMAIN: 'events.helloalfred.com',
};

const staging = {
  API: 'https://staging-consumer-api.hom.events',
  URL: 'https://staging.hom.events',
  AUTH_API: 'https://staging-auth.hom.events',
  FRONTEND_DOMAIN: 'staging.hom.events',
};

const qaserver = {
  API: 'https://consumer-api.kadbu.com',
  URL: 'https://kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  FRONTEND_DOMAIN: 'kadbu.com',
};
// eslint-disable-next-line no-undef
const Config = (env = process.env.REACT_APP_ENV) => {
  if (env === 'production') {
    return production;
  } else if (env === 'staging') {
    return staging;
  } else if (env === 'qaserver') {
    return qaserver;
  } else {
    return development;
  }
};
export default Config();
