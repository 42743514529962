import React from 'react';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import './LiveStream.scss';

const QuestionBox = () => {
  return (
    <div className="px-10 pt-6 questionBox">
      <PrimaryLabel
        labelText="Need to know:"
        className="block mb-4 font-medium text-Color_22 font-brandonBold text-F20"
      />
      <PrimaryLabel
        labelText="Questions?"
        className="block font-medium text-Color_22 font-brandonMedium text-F17"
      />
      <PrimaryLabel
        labelText="Please enter comments and questions in the chat window. Please be mindful, everything you write is public."
        className="block text-base text-color mb-5"
      />
      <PrimaryLabel
        labelText="If your screen in frozen"
        className="block font-medium text-Color_22 font-brandonMedium text-F17"
      />
      <PrimaryLabel
        labelText="Please close this window and rejoin the class from the building page."
        className="block text-base text-color mb-5"
      />
      <PrimaryLabel
        labelText="If you are joining late"
        className="block font-medium text-Color_22 font-brandonMedium text-F17"
      />
      <PrimaryLabel
        labelText="It’s ok! Take some deep breaths, we are so happy to see you! :)"
        className="block text-base text-color"
      />
    </div>
  );
};

export default QuestionBox;
