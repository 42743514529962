import React, { Fragment, useState } from 'react';
import {
  EventCard,
  PrimaryLabel,
  SlideDrawer,
} from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import { IEvent, IUserLoginResponse } from '../../models';
import {
  getEventDetail,
  IEventState,
  markRsvped,
  markedEventStart,
  autoMarkAttendance,
} from '../../redux/events';
import {
  checkPastEvent,
  getDayAndTime,
  getImageUrl,
  differenceInDays,
  checkBuildingWhiteLabeled,
} from '../../utils';
import EventDetail from './EventDetail';
import { IBuldingState } from '../../redux/building';
import { ILoaderState } from '../../redux/loader';
import CommonLoader from '../Common/CommonLoader';
import {
  EVENT_DAY_FORMAT,
  EVENT_TIME_FORMAT,
  NO_DATE_EVENT,
  NO_EVENT,
  NO_UPCOMING_EVENT,
} from '../../constants';
import UnrsvpModal from './UnrsvpModal';
import { IModalState } from '../../redux/modal/modal.state';
import EventRating from './EventRating';
import { ThemeObjectType } from '../../enums';
import { openNoAccessModal } from '../../redux/modal/modal.action';
import NoAccessPopup from '../Common/NoAccessPopup';

const UpcomingList: React.FC<{
  isLoggedIn: boolean;
  openLoginModal: () => void;
  tabName: string;
  filterDate?: string | null;
}> = ({ isLoggedIn, openLoginModal, tabName, filterDate }) => {
  const dispatch = useDispatch();

  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );

  const { events, eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { buildingDetail, buildingAccess } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { openUnrsvp, openNoAccess } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );

  const [pageNo] = useState<number>(0);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [unrsvpId] = useState<number>(0);
  const [isRating, setRating] = useState<boolean>(false);
  const [ratingEventId, setEventId] = useState<number>(null);
  const [tenantUrl, setTenantUrl] = useState<string | null>(null);

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');
  const isBuildingAccess =
    buildingAccess ?? localStorage.getItem('buildingAccess');

  const toggleEventDetailDrawer = async (eventId: number, url: string) => {
    await dispatch(getEventDetail(eventId, buildingDetail?.code));
    setDrawerState(!drawerState);
    setTenantUrl(url);
  };

  const handleMarkRsvp = async (eventId: number) => {
    if (isLoggedIn) {
      const data = {
        buildingCode: buildingDetail?.code,
        reference: 'app',
      };
      if (isBuildingAccess) {
        await dispatch(markRsvped(data, eventId, pageNo));
      } else {
        await dispatch(openNoAccessModal());
      }
      return;
    }
    await openLoginModal();
  };

  const redirectBasedOnUrl = (eventId: number, url: string) => {
    if (url && url.includes('zoom')) {
      window.open(url);
    } else {
      window.open(
        `/livestream/${eventId}/?building-code=${buildingDetail?.code}`
      );
    }
  };

  const goToViewEvent = async (eventId: number, url: string) => {
    if (isLoggedIn && eventId && isBuildingAccess) {
      redirectBasedOnUrl(eventId, url);
      return;
    }
    await openLoginModal();
  };

  const handleRatingClick = async (id: number) => {
    if (isBuildingAccess) {
      setDrawerState(true);
      setRating(true);
      setEventId(id);
    } else {
      await dispatch(openNoAccessModal());
    }
  };

  const onCloseDrawer = () => {
    setDrawerState(false);
    setRating(false);
    setEventId(null);
    setTenantUrl(null);
  };

  const playlistEvent = async (
    eventId: number,
    url: string | null,
    attend: boolean
  ) => {
    if (eventId && isBuildingAccess) {
      if (!attend) {
        const data = {
          buildingCode: buildingDetail?.code,
          reference: 'app',
        };
        await dispatch(autoMarkAttendance(eventId, data));
      }
      redirectBasedOnUrl(eventId, url);
    } else {
      await dispatch(openNoAccessModal());
    }
  };

  const eventStatus = (isOnline: boolean, startDate: string) => {
    const daysCount = differenceInDays(startDate);
    const eventStatusText = `${
      isOnline ? 'Online ' : 'At venue'
    } event begins ${daysCount === 0 ? 'today in' : 'in'}`;
    return eventStatusText;
  };
  const timerToggle = (eventId: number) => {
    const data = {
      isEventStart: true,
      isLiveEvent: true,
    };
    dispatch(markedEventStart(data, eventId, pageNo));
  };

  const noEventMessage = () => {
    switch (tabName) {
      case 'upcoming':
        return NO_UPCOMING_EVENT;
      default:
        return NO_EVENT;
    }
  };

  const onBeginLiveStream = async (
    eventId: number,
    virtual: boolean,
    url: string | null,
    attend?: boolean
  ) => {
    if (eventId && virtual && isBuildingAccess) {
      if (!attend) {
        const data = {
          buildingCode: buildingDetail?.code,
          reference: 'app',
        };
        await dispatch(autoMarkAttendance(eventId, data));
      }
      redirectBasedOnUrl(eventId, url);
    } else {
      await dispatch(openNoAccessModal());
    }
  };

  return (
    <div className="card-wrapper">
      {openUnrsvp && (
        <UnrsvpModal
          modalState={openUnrsvp}
          eventId={eventDetail?.['details']?.['id'] ?? unrsvpId}
          buttonColor={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
          buttonText={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_TEXT
          )}
          tab={tabName}
        />
      )}
      {openNoAccess && <NoAccessPopup modalState={openNoAccess} />}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-0 event-card w-auto md:w-fit">
        {events.length === 0 && !loading && (
          <PrimaryLabel
            labelText={filterDate ? NO_DATE_EVENT : noEventMessage()}
            className="col-span-2 text-center text-MobileFont md:text-HeaderFont pt-8"
          />
        )}
        {events.map((item: IEvent, index: number) => {
          return (
            <Fragment key={index}>
              <EventCard
                eventImage={item.image ? getImageUrl(item.image) : null}
                eventTitle={item.displayName}
                eventDayMonth={getDayAndTime(
                  item.startDatetime,
                  EVENT_DAY_FORMAT,
                  item.virtual ? item.regionTimezone : item.zoneValue
                )}
                startTime={getDayAndTime(
                  item.startDatetime,
                  EVENT_TIME_FORMAT,
                  item.virtual ? item.regionTimezone : item.zoneValue
                )}
                endTime={getDayAndTime(
                  item.endDatetime,
                  EVENT_TIME_FORMAT,
                  item.virtual ? item.regionTimezone : item.zoneValue
                )}
                venue={
                  item.virtual
                    ? 'Online'
                    : buildingDetail?.name ?? 'Dummy Building'
                }
                venueLocation={item.virtual ? 'Streamed on app' : item.location}
                onEventClick={() =>
                  toggleEventDetailDrawer(item.id, item.tenantUrl)
                }
                iWantRsvp={
                  access && checkPastEvent(item.endDatetime)
                    ? false
                    : !item.rsvp
                }
                isOnlineEvent={item.virtual}
                isAtVenue={!item.virtual}
                rsvped={
                  checkPastEvent(item.startDatetime) && tabName === 'playlist'
                    ? false
                    : item.rsvp
                }
                pastEvent={access && checkPastEvent(item.endDatetime)}
                isPlayVideo={item.virtual}
                attendee={item.attended}
                rating={item.rating ?? 0}
                onIWantRsvpClick={() => handleMarkRsvp(item.id)}
                onViewEvent={() => goToViewEvent(item.id, item.tenantUrl)}
                eventStatus={eventStatus(item.virtual, item.startDatetime)}
                buttonColor={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_COLOR
                )}
                buttonText={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_TEXT
                )}
                isWhitelabel={Boolean(isWhitelabel)}
                onRatingClick={() => handleRatingClick(item.id)}
                onPlayVideo={() =>
                  playlistEvent(item.id, item.tenantUrl, item.attended)
                }
                startTimeInUTC={item.startDatetime}
                timerToggle={() => timerToggle(item.id)}
                isEventStart={access && item.isEventStart}
                isLiveEvent={access && item.isLiveEvent}
                isWaitList={access && item.waitlist}
                eventTitleClass="font-brandonMedium md:text-F20 mb-0 md:h-16"
                eventDayClass="font-brandonMedium md:text-F18"
                venueClass="font-brandonMedium md:text-F18"
                onBeginLiveStream={() =>
                  onBeginLiveStream(
                    item.id,
                    item.virtual,
                    item.tenantUrl,
                    item.attended
                  )
                }
                eventTimeZone={item.regionTimezone}
                timezone={
                  item.virtual ? item.regionTimezoneAbbrevation : item.shortName
                }
              />
            </Fragment>
          );
        })}
        <SlideDrawer
          show={drawerState}
          showCloseIcon={true}
          closePane={onCloseDrawer}
          showIconClass="p-8 md:p-0"
          showIconDivClass="pt-0 pb-0 md:pt-4"
        >
          {isRating ? (
            <EventRating eventId={ratingEventId} closePane={onCloseDrawer} />
          ) : (
            <EventDetail
              buttonText={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_TEXT
              )}
              buttonColor={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_COLOR
              )}
              tenantUrl={tenantUrl}
              isLoggedIn={isLoggedIn}
              buildingAccess={Boolean(isBuildingAccess)}
            />
          )}
        </SlideDrawer>
      </div>
      <div className="p-8 w-full">
        <CommonLoader
          loading={loading}
          color={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
          className={`${events.length !== 0 && `position-relative`}`}
        />
      </div>
    </div>
  );
};

export default UpcomingList;
