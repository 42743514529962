export interface IModalState {
  open: boolean;
  openRegistration: boolean;
  openUnrsvp: boolean;
  openTermCondition: boolean;
  openForgotPassword: boolean;
  openNoAccess: boolean;
  blockRegistration: boolean;
  opneReport: boolean;
}

export const initialModalState: IModalState = {
  open: false,
  openRegistration: false,
  openUnrsvp: false,
  openTermCondition: false,
  openForgotPassword: false,
  openNoAccess: false,
  blockRegistration: false,
  opneReport: false,
};
