import { apiStatusCheck } from '../../utils/index';
import { uploadImageApi } from './common.service';
import * as loadingTypes from '../loader/loader.constant';
// import * as formTypes from '../form/form.constant';

export const imageUpload = (file) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const imageData = await uploadImageApi(file);
  if (apiStatusCheck(imageData)) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return imageData?.['data']?.['data']?.['id'];
  }
};

export const stopLoading = () => {
  return {
    type: loadingTypes.LOADING_STOPED,
  };
};
