import { AxiosResponse } from 'axios';
import { BUILDING_NAME } from '../../constants';
import { http } from '../common/http.service';
import {
  IBuildingAccessResponse,
  IBuildingDetailResponse,
  IBuildingUnitResponse,
  IClusterDetailResponse,
  IGenerateReport,
} from './../../models/building';

export const validateBuildingCodeApi = async (
  buildingName: string
): Promise<IBuildingDetailResponse> => {
  return http
    .get(`property/verify/${buildingName}?building_code=1`, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IBuildingDetailResponse>) => {
      return response.data;
    });
};

export const validateClusterCodeApi = async (
  clusterName: string
): Promise<IClusterDetailResponse> => {
  return http
    .get(`property/verify/${clusterName}?cluster_code=1`, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IClusterDetailResponse>) => {
      return response.data;
    });
};

export const getBuildingBlockApi = async (
  buildingCode: string = BUILDING_NAME
): Promise<IBuildingUnitResponse> => {
  return http
    .get(`/property/blocks/${buildingCode}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IBuildingUnitResponse>) => {
      return response.data;
    });
};

export const validateBuildingAccessApi = async (
  buildingName: string
): Promise<IBuildingAccessResponse> => {
  return http
    .get(`permission/${buildingName}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IBuildingAccessResponse>) => {
      return response.data;
    });
};

export const generateReportApi = async (
  data: IGenerateReport,
  buildingCode: string
): Promise<IGenerateReport> =>
  await http.post(`/report/${buildingCode}/`, data, {
    errorHandler: true,
  });
