import { findIndexFromArrayOfObject } from './../../utils/index';
import * as eventTypes from './events.constant';
import { IEventState, initialEventState } from './events.state';

export const eventReducer = (
  state: IEventState = initialEventState,
  action: {
    type: string;
    page: number;
    payload: any;
    eventTotal: number;
    eventId: number;
    tab: string;
  }
): IEventState => {
  switch (action.type) {
    case eventTypes.CLEAR_EVENTS:
      return {
        ...state,
        events: [],
      };
    case eventTypes.GET_EVENTS:
      return {
        ...state,
        events: [...state.events, ...action.payload],
        eventCount: action.eventTotal,
      };
    case eventTypes.GET_EVENT_DATE_COUNT:
      return {
        ...state,
        eventDateCount: action.payload,
      };
    case eventTypes.GET_FILTER_EVENTS:
      return {
        ...state,
        events: [...state.events, ...action.payload],
        eventCount: action.eventTotal,
      };
    case eventTypes.GET_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: action.payload,
      };
    case eventTypes.MARK_UNMARK_RSVP:
      const eventData = state.events.filter(
        (item) => action.eventId === item.id
      );
      const index = findIndexFromArrayOfObject(
        state.events,
        'id',
        eventData[0]
      );

      state.events[index].rsvp = action.payload.rsvp;
      if (action.tab === 'my-rsvps') {
        state.events = [...state.events.splice(1, index)];
      }
      return {
        ...state,
        events: [...state.events],
        eventDetail: {
          ...state.eventDetail,
          details: { ...state.eventDetail.details, ...action.payload },
        },
      };
    case eventTypes.MARK_LIVESTREAM_UNMARK_RSVP:
      return {
        ...state,
        liveStreamDetail: {
          ...state.liveStreamDetail,
          details: { ...state.liveStreamDetail.details, ...action.payload },
        },
      };
    case eventTypes.GET_LIVESTREAM_DETAIL:
      return {
        ...state,
        liveStreamDetail: action.payload,
      };
    case eventTypes.MARK_EVENT_START:
      const eventDataArray = state.events.filter(
        (item) => action.eventId === item.id
      );
      if (eventDataArray?.length > 0) {
        const indexNumber = findIndexFromArrayOfObject(
          state.events,
          'id',
          eventDataArray[0]
        );
        state.events[indexNumber].isEventStart = action.payload.isEventStart;
        state.events[indexNumber].isLiveEvent = action.payload.isLiveEvent;
      }

      return {
        ...state,
        events: [...state.events],
        eventDetail: {
          ...state.eventDetail,
          details: { ...state.eventDetail.details, ...action.payload },
        },
      };
    default:
      return state;
  }
};
