import React, { useRef, useEffect, useState } from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { IResetPasswordDetails } from '../../models';
import {
  checkBuildingWhiteLabeled,
  getImageUrl,
  getQueryParameters,
  getSubDomain,
  HOM_LOGO,
} from '../../utils';
import { useDispatch } from 'react-redux';
import {
  resetPassword,
  verifyUserToken,
  IUserLoginState,
  verifyEmailToken,
} from '../../redux/authentication';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonType, ThemeObjectType } from '../../enums';
import { ILoaderState } from '../../redux/loader';
import { EN, BUILDING_NAME } from '../../constants/index';
import { IBuldingState } from '../../redux/building';
import { validateBuildingCodeBeforeLogin } from '../../redux/building';
import CommonLoader from '../Common/CommonLoader';
import ErrorMessage from './ErrorMessage';
import './Login.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const subDomain = getSubDomain();
  const token = getQueryParameters('reset-token');
  const email = getQueryParameters('email');
  const [resetLoader, setResetLoader] = useState<boolean>(true);
  const { buildingDetail } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  useEffect(() => {
    async function getVerifyToken() {
      if (token && email) {
        dispatch(verifyEmailToken(true));
      }
      const verifyTokenObj = {
        token,
        email,
        building_code: subDomain ?? BUILDING_NAME,
      };
      setResetLoader(true);
      await dispatch(
        validateBuildingCodeBeforeLogin(subDomain ?? BUILDING_NAME)
      );
      await dispatch(verifyUserToken(verifyTokenObj));
      setResetLoader(false);
    }
    getVerifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { verifyToken } = useSelector(
    (state: { authentication: IUserLoginState }) => state.authentication
  );
  const { register, errors, handleSubmit, watch } =
    useForm<IResetPasswordDetails>();
  const password = useRef({});
  password.current = watch('password', '');
  const onSubmit = async (data: IResetPasswordDetails) => {
    delete data.newPassword;
    data['token'] = token;
    data['email'] = email;
    data['building_code'] = subDomain ?? BUILDING_NAME;
    await dispatch(resetPassword(data));
    dispatch(verifyEmailToken(false));
    push('/');
  };
  const isError = verifyToken?.['error']?.['code'] ? true : false;

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  return (
    <>
      {resetLoader ? (
        <CommonLoader
          loading={resetLoader}
          color={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
        />
      ) : isError ? (
        <ErrorMessage
          message={verifyToken?.error?.message}
          logo={checkBuildingWhiteLabeled(buildingDetail, ThemeObjectType.LOGO)}
          buttonText={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_TEXT
          )}
          buttonColor={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
        />
      ) : (
        <div className="flex flex-col items-center pt-20">
          <div className="reset-password-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <img
                src={
                  isWhitelabel
                    ? getImageUrl(
                        checkBuildingWhiteLabeled(
                          buildingDetail,
                          ThemeObjectType.LOGO
                        )
                      )
                    : HOM_LOGO
                }
                alt="logo"
                className="max-h-1/4"
              />
              <div className="mt-8">
                <PrimaryLabel
                  labelText={EN.login.ENTER_NEW_PASSWORD}
                  className="text-Color_22 font-brandonBold forgot_heading "
                />
              </div>
              <div className="pt-4 mt-4">
                <PrimaryLabel
                  labelText={EN.login.ENTER_PASSWORD}
                  className="field_label"
                />
                <Input
                  label=""
                  placeholder=""
                  name="password"
                  inputType="password"
                  ref={register({
                    required: true,
                  })}
                  isError={errors.password ? true : false}
                />
              </div>
              <div className="pt-4 mt-4">
                <PrimaryLabel
                  labelText={EN.login.RE_ENTER_PASSWORD}
                  className="field_label"
                />
                <Input
                  label=""
                  placeholder=""
                  name="newPassword"
                  inputType="password"
                  ref={register({
                    required: true,
                    validate: (value) =>
                      value === password.current ||
                      EN.login.PASSWORD_NOT_MATCHED,
                  })}
                  isError={errors.newPassword ? true : false}
                />
                {errors.newPassword && (
                  <PrimaryLabel
                    labelText={errors.newPassword.message}
                    className="text-Color_42"
                  />
                )}
              </div>
              <div className="mt-12">
                <Button
                  label={EN.login.CREATE_PASSWORD}
                  className={`block w-full md:w-full ${
                    loading && 'button_disable'
                  }`}
                  type={ButtonType.SUBMIT}
                  backgroundColor={
                    checkBuildingWhiteLabeled(
                      buildingDetail,
                      ThemeObjectType.BUTTON_COLOR
                    ) ?? '#f36b7f'
                  }
                  buttonText={
                    checkBuildingWhiteLabeled(
                      buildingDetail,
                      ThemeObjectType.BUTTON_TEXT
                    ) ?? '#fefefe'
                  }
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
