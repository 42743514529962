import {
  Button,
  Input,
  Loader,
  PrimaryLabel,
} from '@alfredclub/hom-planner-theme';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IUserLogin } from '../../models/user';
import {
  checkBuildingWhiteLabeled,
  getImageUrl,
  HOM_LOGO,
  onTabChange,
} from '../../utils';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/authentication';
import {
  closeModal,
  openRegistraionModal,
  openForgotPasswordModal,
  openBlockRegistrationModal,
} from '../../redux/modal/modal.action';
import './Login.scss';
import { IBuldingState, validateBuildingAccess } from '../../redux/building';
import { clearEvents, getEvents } from '../../redux/events';
import { CURRENT_DATE, EN } from '../../constants';
import { ILoaderState } from '../../redux/loader';
import { ThemeObjectType } from '../../enums';

const LoginPopup: React.FC<{ modalState: boolean }> = ({ modalState }) => {
  const { register, handleSubmit, errors } = useForm<IUserLogin>();
  const { buildingDetail } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(document.location.search);
  const tabName = queryParams.get('tab');

  const onSubmit = async (data: IUserLogin) => {
    const loginObj = {
      username: data.username.trim().toLowerCase(),
      password: data.password,
    };
    await dispatch(login(loginObj));
    await dispatch(clearEvents());

    await dispatch(
      getEvents(buildingDetail.code, CURRENT_DATE, onTabChange(tabName))
    );
    await close();
    await dispatch(validateBuildingAccess(buildingDetail.code));
  };

  const close = async () => {
    await dispatch(closeModal());
  };

  const openRegistration = async () => {
    if (buildingDetail?.registrationActive) {
      await close();
      await dispatch(openRegistraionModal());
    } else {
      await dispatch(openBlockRegistrationModal());
    }
  };

  const isWhitelabel =
    buildingDetail?.whitelabelActive ??
    localStorage.getItem('whitelabelActive');

  const openForgotPassword = async () => {
    await close();
    await dispatch(openForgotPasswordModal());
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="login-modal h-full md:h-auto rounded-none md:rounded-lg transform translate-y-0 md:translate-y-8"
      shouldCloseOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-2 gap-y-0 login-wrapper">
          <img
            src={
              isWhitelabel
                ? getImageUrl(
                    checkBuildingWhiteLabeled(
                      buildingDetail,
                      ThemeObjectType.LOGO
                    )
                  )
                : HOM_LOGO
            }
            className="max-h-1/4"
            alt="logo"
          />
          <PrimaryLabel labelText="Login" className="sub-header login-title" />
          <div className="pt-16">
            <PrimaryLabel labelText="Email Address" />
            <Input
              label=""
              name="username"
              inputType="email"
              isError={errors?.['username'] ? true : false}
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
          </div>
          <div className="pt-8">
            <PrimaryLabel labelText="Password" />
            <Input
              label=""
              placeholder=""
              name="password"
              inputType="password"
              ref={register({ required: true })}
              isError={errors?.['password'] ? true : false}
            />
          </div>
          <span className="link-forgot" onClick={openForgotPassword}>
            {EN.login.FORGOT_PASSWORD}
          </span>
          <div className="pt-10 block justify-center md:min-w-full">
            {Object.keys(errors).length > 0 && (
              <div className="login-error">
                <span>Please fill fields marked above*</span>
              </div>
            )}
            <Button
              label="Login"
              className={`w-full block ${loading && 'btn-disable'}`}
              backgroundColor={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_COLOR
              )}
              buttonText={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_TEXT
              )}
            />
            <Loader
              loading={loading}
              size="1rem"
              position="center"
              className="login-loader"
              color={
                checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_COLOR
                ) ?? '#f36b7f'
              }
            />
          </div>
          <div className="inline-grid justify-center">
            <PrimaryLabel
              labelText="Don’t have an account?"
              className="text-center block pt-8 pb-2"
            />
            <Button
              label="Create Account"
              type="button"
              className="btn-create"
              onClick={openRegistration}
              buttonText={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_COLOR
              )}
              btnBorderColor={checkBuildingWhiteLabeled(
                buildingDetail,
                ThemeObjectType.BUTTON_COLOR
              )}
            />
          </div>
        </div>
      </form>
    </ModalPopup>
  );
};

export default LoginPopup;
