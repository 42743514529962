export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_REGISTRATION = 'OPEN_REGISTRATION';
export const OPEN_UNRSVP = 'OPEN_UNRSVP';
export const OPEN_TERM_CONDITION = 'OPEN_TERM_CONDITION';
export const CLOSE_TERM_MODAL = 'CLOSE_TERM_MODAL';
export const OPEN_FORGOT_PASSWORD = 'OPEN_FORGOT_PASSWORD';
export const OPEN_NO_ACCESS = 'OPEN_NO_ACCESS';
export const BLOCK_REGISTRATION = 'BLOCK_REGISTRATION';
export const CLOSE_BLOCK_REGISTRATION = 'CLOSE_BLOCK_REGISTRATION';
export const OPEN_REPORT = 'OPEN_REPORT';
