// tslint:disable: semicolon
import React, { Component } from 'react';
import EmojiPicker from './EmojiPicker';
import { getImageUrl } from '../../../utils';
import { IChatMessageFormProps, IChatMessageFormState } from '../../../models';

class MessageForm extends Component<
  IChatMessageFormProps,
  IChatMessageFormState
> {
  chatMessageInput: React.RefObject<HTMLInputElement>;
  chatFormWrapper: React.RefObject<HTMLFormElement>;
  chatSubmitButton: React.RefObject<HTMLButtonElement>;
  outsideEventClick: any;
  submitButton: HTMLButtonElement;
  constructor(props: IChatMessageFormProps) {
    super(props);
    this.state = {
      btnDisabled: true,
      text: '',
      hideEmoji: false,
    };
    this.chatMessageInput = React.createRef();
    this.chatFormWrapper = React.createRef();
    this.chatSubmitButton = React.createRef();
  }
  componentDidMount = () => {
    this.chatMessageInput.current.focus();
    const { body = null, editForm = false } = this.props;
    if (editForm) {
      this.outsideEventClick = document.addEventListener(
        'mousedown',
        this.handleClickOutside
      );
    }
    if (body) {
      this.setState({
        text: body,
      });
    }
  };
  componentWillUnmount() {
    if (this.outsideEventClick) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
  handleClickOutside = (event) => {
    const { editForm = false, onEditFormSubmit } = this.props;
    if (editForm) {
      if (
        this.chatFormWrapper.current &&
        !this.chatFormWrapper.current.contains(event.target)
      ) {
        onEditFormSubmit();
      }
    }
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    const {
      editForm = false,
      onMessageSend,
      onUpdateMessage,
      messageId,
      onEditFormSubmit,
    } = this.props;
    const { text, hideEmoji } = this.state;
    if (editForm) {
      onEditFormSubmit();
      onUpdateMessage(text, messageId);
    } else {
      onMessageSend(text);
    }
    this.chatMessageInput.current.value = '';
    this.setState({
      btnDisabled: true,
      text: '',
      hideEmoji: !hideEmoji,
    });
  };
  onChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({
      btnDisabled: value ? false : true,
      text: value,
    });
  };
  addEmoji = (e) => {
    const sym = e.unified.split('-');
    const codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    const emoji = String.fromCodePoint(...codesArray);
    this.setState({
      text: this.state.text + emoji,
      btnDisabled: false,
    });
  };

  render() {
    const {
      disabled,
      askUserName,
      pastEvent,
      editFormStyle = null,
      editForm = false,
    } = this.props;
    const { btnDisabled, text, hideEmoji } = this.state;
    return (
      <>
        <form
          ref={this.chatFormWrapper}
          className={
            editFormStyle
              ? editFormStyle
              : askUserName || pastEvent
              ? 'transparency MessageForm'
              : 'MessageForm'
          }
          onSubmit={this.handleFormSubmit}
        >
          <div className="input-container">
            <input
              type="text"
              ref={this.chatMessageInput}
              placeholder="Type your message here"
              disabled={disabled || pastEvent}
              onChange={this.onChange}
              value={text}
              onFocus={() => this.setState({ hideEmoji: !hideEmoji })}
            />
          </div>
          {!editForm && (
            <EmojiPicker
              addEmoji={this.addEmoji}
              disabled={disabled || pastEvent}
              hideEmoji={hideEmoji}
            />
          )}
          <div className="button-container">
            <button
              type="submit"
              className="chat-submit-btn"
              ref={this.chatSubmitButton}
              disabled={disabled || btnDisabled || pastEvent}
            >
              <img
                alt="message-send"
                className="send-msg"
                src={getImageUrl('ConsumerApp/send-on-chat.svg', true)}
              />
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default MessageForm;
