import { IEventDateCount } from './../../models/event';
import { IEventDetail, Pagination } from '../../models';

export interface IEventState extends Pagination {
  eventCount: number;
  eventDetail: IEventDetail;
  liveStreamDetail: IEventDetail;
  events: IEventDetail[];
  eventDateCount: IEventDateCount;
}

export const initialEventState: IEventState = {
  events: [],
  eventCount: 0,
  eventDateCount: {},
  eventDetail: {
    eventDescription: '',
    instructorDetails: {},
    details: {
      displayName: '',
      startDatetime: '',
      endDatetime: '',
      id: null,
      image: '',
      virtual: false,
      location: '',
      rsvp: false,
      waitlist: false,
      attended: false,
      rating: 0,
      isEventStart: false,
      isLiveEvent: false,
      tenantUrl: '',
      waitlistActive: false,
    },
  },
  liveStreamDetail: {
    eventDescription: '',
    instructorDetails: {},
    details: {
      displayName: '',
      startDatetime: '',
      endDatetime: '',
      id: null,
      image: '',
      virtual: false,
      location: '',
      rsvp: false,
      waitlist: false,
    },
    id: null,
    tenantUrl: '',
    playbackUrl: '',
    attendee: false,
  },
};
