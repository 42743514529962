import React from 'react';
import Modal from 'react-modal';
import { ModalProps } from './types';
import { getImageUrl } from '../../utils';
import './Modal.scss';

const ModalPopup = (props: ModalProps) => {
  const {
    isOpen,
    children,
    className,
    shouldCloseOnOverlayClick,
    onRequestClose,
    closebtnClass,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      onRequestClose={onRequestClose}
      className={`modal-body ${className}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
    >
      {children}
      <button className={`btn-close ${closebtnClass}`}>
        <img
          src={getImageUrl('ConsumerApp/closemodalicon.svg', true)}
          alt="icon"
          onClick={onRequestClose}
        />
      </button>
    </Modal>
  );
};

export default ModalPopup;
