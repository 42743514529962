import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from '../../hooks';
import {
  getUserDetail,
  IAccountsState,
  updateUserProfile,
} from '../../redux/user';
import { useSelector, useDispatch } from 'react-redux';
import { IUserDetail } from '../../models';
import './Profile.scss';
import { IBuldingState } from '../../redux/building';
import CommonLoader from '../Common/CommonLoader';
import { ILoaderState } from '../../redux/loader';
import { useHistory } from 'react-router-dom';
import { checkBuildingWhiteLabeled } from '../../utils';
import { ThemeObjectType } from '../../enums';
import { EN } from '../../constants';

const UserProfile = () => {
  const { register, handleSubmit, reset } = useForm<IUserDetail>();
  const { buildingDetail } = useSelector(
    (state: { building: IBuldingState }) => state.building
  );
  const dispatch = useDispatch();

  const { userDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { push } = useHistory();

  useFetch(getUserDetail, [buildingDetail?.code]);
  useEffect(() => {
    reset(userDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail, reset]);

  const onSubmit = async (data: IUserDetail) => {
    data.building = userDetail?.['building'];
    data.unit = userDetail?.['unit'];
    await dispatch(updateUserProfile(data, buildingDetail?.code));
    push('/?tab=upcoming');
  };

  return (
    <>
      {userDetail && Object.keys(userDetail).length > 0 ? (
        <div className="user-profile-wrapper sm:container md:container lg:container xl:container md:mx-auto md:mt-24 px-8 pt-8 md:px-8 md:pt-2 lg:px-42 lg:py-0">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Button
                label={EN.profile.BTN_SAVE}
                className="hidden md:block font-brandonBold text-F18"
                backgroundColor={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_COLOR
                )}
                buttonText={checkBuildingWhiteLabeled(
                  buildingDetail,
                  ThemeObjectType.BUTTON_TEXT
                )}
              />
              <PrimaryLabel
                labelText={EN.profile.TITLE}
                className="sub-header header-title font-brandonBold text-F25"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-x-8 mt-12">
              <div>
                <PrimaryLabel labelText={EN.profile.FNAME} />
                <Input label="First Name" name="firstName" ref={register} />
              </div>
              <div>
                <PrimaryLabel labelText={EN.profile.LNAME} />
                <Input label="Last Name" name="lastName" ref={register} />
              </div>
              <div>
                <PrimaryLabel labelText={EN.profile.EMAIL} />
                <Input
                  label="Email Address"
                  name="email"
                  inputType="email"
                  ref={register()}
                  className="pointer-events-none bg-Color_35"
                />
              </div>
              <div>
                <PrimaryLabel labelText={EN.profile.PHONE} />
                <Input
                  label="Phone Number"
                  name="cellPhone"
                  ref={register({
                    required: false,
                    pattern: {
                      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                      message: 'Invalid phone number',
                    },
                  })}
                />
              </div>
              <div>
                <PrimaryLabel labelText={EN.profile.BUILDING} />
                <Input
                  label="Building"
                  name="building.name"
                  ref={register}
                  className="pointer-events-none bg-Color_35"
                />
              </div>
              <div>
                <PrimaryLabel labelText={EN.profile.UNIT} />
                <Input
                  label="Unit number"
                  name={`${
                    userDetail?.['unit']?.['buildingRelated'] ? `unit.name` : ``
                  }`}
                  ref={register}
                  className="pointer-events-none bg-Color_35"
                />
              </div>
              <div className="md:col-span-2 flex">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="newsDigest"
                  ref={register}
                />
                <span>{EN.profile.WEEKLY_DIGEST}</span>
              </div>
              <div className="md:col-span-2 flex">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="marketingEmails"
                  ref={register}
                />
                <span>{EN.profile.MARKETING_EMAIL}</span>
              </div>
              <div>
                <Button
                  label={EN.profile.BTN_SAVE}
                  className="md:hidden mb-8 font-brandonBold text-F18"
                  backgroundColor={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_COLOR
                  )}
                  buttonText={checkBuildingWhiteLabeled(
                    buildingDetail,
                    ThemeObjectType.BUTTON_TEXT
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <CommonLoader
          loading={loading}
          color={checkBuildingWhiteLabeled(
            buildingDetail,
            ThemeObjectType.BUTTON_COLOR
          )}
        />
      )}
    </>
  );
};

export default UserProfile;
