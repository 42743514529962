import { BUILDING_NAME } from './../../constants';
import { IBuildingDetail, IClusterDetail } from '../../models/building';
import { TinyObject } from '../../models';

export interface IBuldingState {
  buildingDetail: IBuildingDetail;
  fetchedBuildingDetail: boolean;
  buildingUnit: TinyObject[];
  clusterDetail: IClusterDetail;
  isCluster: boolean;
  buildingAccess: boolean;
  clientReport: object;
}

export const initialBuildingState: IBuldingState = {
  buildingDetail: { code: BUILDING_NAME },
  fetchedBuildingDetail: false,
  buildingUnit: [],
  clusterDetail: {},
  isCluster: false,
  buildingAccess: false,
  clientReport: {},
};
