import moment from 'moment';

export const EVENT_DAY_FORMAT = 'dddd, MMM D';
export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';
export const EVENT_TIME_FORMAT = 'h:mma';
export const EVENT__DATE_FILTER_FORMAT = 'MMMM DD yyyy';
export const CURRENT_DATE = moment().format(EVENT_DATE_FORMAT);
export const PAST_DATE = moment().subtract(1, 'days').format(EVENT_DATE_FORMAT);
export const BUILDING_NAME = 'happy';
export const PARAM = 'upcoming';
export const RECORDS_PAGE = 0;
export const RECORDS_LIMIT = 10;
export const TOAST_POSITION = 'bottomCenter';
export const TOAST_PROGRESS_BAR = false;
export const AUTH_BASE_URL = '/auth';
export const TickImage =
  'https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg';
export const TERMS_CONDITION_LINK = 'http://www.iamhom.com/terms.html';
export const PRIVACY_POLICY = 'https://www.iubenda.com/privacy-policy/67339644';
export const NOEVENT_ONDATE = 'No events scheduled on selected date';
export const NOUPCOMING_EVENT = 'No events scheduled yet';
export const NO_EVENT = 'No events available!';

export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/';
export const VIMEO_EMBED = 'https://player.vimeo.com/video/';

export const TOAST_MESSAGES = {
  user: {
    updated: 'Profile updated successfully',
  },
  contact: {
    email: 'Message sent successfully',
  },
  rating: {
    message: 'Thanks for your feedback!',
  },
  report: {
    noRecord: 'Report unavailable, due to empty records',
  },
};

export const tenantProfile = [
  {
    id: 1,
    label: 'My Profile',
    imgSrc: 'ConsumerApp/Myprofiledropdown1.svg',
  },
  {
    id: 2,
    label: 'Generate event report',
    imgSrc: 'ConsumerApp/downloadicon.svg',
  },
  {
    id: 3,
    label: 'Contact us',
    imgSrc: 'ConsumerApp/Contactusdropdown3.svg',
  },
  {
    id: 4,
    label: 'Log out',
    imgSrc: 'ConsumerApp/logoutdropdown4.svg',
  },
  // {
  //   id: 5,
  //   label: 'My HOM Coins',
  //   imgSrc: 'ConsumerApp/HOMCoinsdropdown2.svg',
  // },
];

export const currentYear = parseInt(moment().format('YYYY'), 10);
export const currentMonth = parseInt(moment().format('M'), 10);

export const MonthOptions = [
  {
    id: 1,
    name: `January`,
  },
  {
    id: 2,
    name: `February`,
  },
  {
    id: 3,
    name: `March`,
  },
  {
    id: 4,
    name: `April`,
  },
  {
    id: 5,
    name: `May`,
  },
  {
    id: 6,
    name: `June`,
  },
  {
    id: 7,
    name: `July`,
  },
  {
    id: 8,
    name: `August`,
  },
  {
    id: 9,
    name: `September`,
  },
  {
    id: 10,
    name: `October`,
  },
  {
    id: 11,
    name: `November`,
  },
  {
    id: 12,
    name: `December`,
  },
];

export const yearOptions = [
  {
    id: Number(`${currentYear - 1}`),
    name: `${currentYear - 1}`,
  },
  {
    id: Number(`${currentYear}`),
    name: `${currentYear}`,
  },
  {
    id: Number(`${currentYear + 1}`),
    name: `${currentYear + 1}`,
  },
];

export const NO_UPCOMING_EVENT = 'No events scheduled yet';
export const NO_DATE_EVENT = 'No events scheduled on selected date';

export const EN = {
  tabs: {
    UPCOMING: 'Upcoming',
    MY_RSVP: 'My-RSVPs',
    PLAYLIST: 'Playlist',
    EVENT_ON: 'Events on',
  },
  event: {
    EVENTS_ON: 'Events on',
    ONLINE_EVENT: 'Online Event',
    AT_VENUE: 'At Venue Event',
    EVENT_LIVE: 'Event is live',
    BTN_RSVP: 'I want to RSVP',
    WAIT_LIST:
      'Slots are full! You are on the waitlist. Don’t worry, you can be upgraded to RSVP status if someone cancels their spot',
    THANKS_RSVP:
      'Thanks for RSVPing to this event. We’ve reserved a spot for you!',
    UNRSVP: 'unRSVP',
    BEGIN_LIVE: 'Begin Livestream',
    VIEW_EVENT: 'View event',
    ABOUT_EVENT: 'About the event',
    ABOUT_INST: 'About the instructor',
  },
  login: {
    LOGIN: 'Login',
    FORGOT_PASSWORD: 'Forgot Password?',
    PASSWORD: 'Password',
    EMAIL_ADDRESS: 'Email address',
    LOGIN_TO_ACCOUNT: 'Login to my account',
    ERROR_MESSAGE: 'Please fill fields marked above*',
    RESET_PASSWORD: 'Reset Password',
    FORGOT_YOUR_PASSWORD: 'Forgot Your Password?',
    FORGOT_PASSWORD_STATEMENT_1: 'It’s okay.. It happens to the best of us!',
    FORGOT_PASSWORD_STATEMENT_2:
      'Enter your email address below to receive password reset instructions',
    ENTER_NEW_PASSWORD: 'Enter new password',
    ENTER_PASSWORD: 'Enter password',
    RE_ENTER_PASSWORD: 'Re-enter password',
    CREATE_PASSWORD: 'Create Password',
    RESET_MY_PASSWORD: 'Reset my password',
    TOKEN_ERROR_MESSAGE_1: 'This token request is invalid.',
    PASSWORD_NOT_MATCHED: 'The passwords do not match',
  },
  chat: {
    title: 'Welcome HOM',
    subHeading: 'Connect with us and your fellow participants here',
  },
  homEvent: {
    staging_heading: 'kadbu.com is an incomplete link address',
    prod_heading: 'events.helloalfred.com is an incomplete link address',
    subHeading:
      'Please enter your building name as a part of the URL to see your events',
    prod_example: 'For eg. buildingname.events.helloalfred.com',
    staging_example: 'For eg. buildingname.kadbu.com',
    message:
      'If you are still not sure what the right link is, please check with your building manager!',
  },
  profile: {
    TITLE: 'My Profile',
    BTN_SAVE: 'Save changes',
    FNAME: 'First Name',
    LNAME: 'Last Name',
    EMAIL: 'Email Address',
    PHONE: 'Phone Number',
    BUILDING: 'Building',
    UNIT: 'Unit number',
    WEEKLY_DIGEST: 'Subscribe to weekly upcoming digest',
    MARKETING_EMAIL:
      'I consent to receive marketing emails about your services',
  },
  rating: {
    Title: 'Please rate this event',
    SubTitle: 'We’d love to know your thoughts about the event!',
    Placeholder: 'Type in your feedback here',
    BtnSubmit: 'Submit',
  },
  noAccess: {
    Title: 'Sorry! You do not have access to this building',
    SubTitle:
      'Please check with your building manager for your building events page website',
  },
  blockRegister: {
    Title: 'Sorry, we are not accepting new user registrations.',
    SubTitle: 'Please contact with your building manager.',
  },
  report: {
    Title: 'Generate Event Report',
    Month: 'Select Month',
    Year: 'Select Year',
    Download: 'Download',
  },
};
