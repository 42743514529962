import React from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { EN } from '../../constants/index';
import ForgotPassword from './ForgotPassword';
import { ButtonType } from '../../enums';
import { openForgotPasswordModal } from '../../redux/modal/modal.action';
import { IModalState } from '../../redux/modal/modal.state';
import './Login.scss';

const ErrorMessage: React.FC<{
  message?: string;
  logo: string | null;
  buttonText: string | null;
  buttonColor: string | null;
}> = ({ message, logo, buttonText, buttonColor }) => {
  const dispatch = useDispatch();
  const { openForgotPassword } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const openFPasswordModal = async () => {
    await dispatch(openForgotPasswordModal());
  };
  return (
    <>
      {openForgotPassword && (
        <ForgotPassword
          modalState={openForgotPassword}
          logo={logo}
          buttonText={buttonText}
          buttonColor={buttonColor}
        />
      )}
      <div className="flex flex-col items-center pt-20 reset-password-wrapper">
        <div>
          <img
            src={
              logo
                ? getImageUrl(logo)
                : getImageUrl('ConsumerApp/homlogonew.svg', true)
            }
            alt="logo"
            className="max-h-1/4"
          />
          <div className="mt-10">
            <img src={getImageUrl('Vendor/tokenalert.svg', true)} alt="logo" />
          </div>
          <div className="mt-6">
            <PrimaryLabel
              labelText={message}
              className="text-Color_22 text-F18 font-brandonMedium"
            />
          </div>

          <div className="mt-6">
            <Button
              label={EN.login.RESET_MY_PASSWORD}
              className={`block w-full md:w-full`}
              type={ButtonType.BUTTON}
              backgroundColor={buttonColor ?? '#f36b7f'}
              buttonText={buttonText ?? '#fefefe'}
              onClick={openFPasswordModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
