import * as loadingTypes from './loader.constant';
import { ILoaderState, initialLoaderState } from './loader.state';

export const loadingReducer = (
  state: ILoaderState = initialLoaderState,
  action: { type: string }
): ILoaderState => {
  switch (action.type) {
    case loadingTypes.LOADING_STARTED:
      return {
        loading: true,
      };
    case loadingTypes.LOADING_STOPED:
      return {
        loading: false,
      };
    default:
      return state;
  }
};
