import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HOM_LOGO } from '../../utils';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch } from 'react-redux';
import { TERMS_CONDITION_LINK, PRIVACY_POLICY } from '../../constants';
import { updateTermOfServices } from '../../redux/user';

const TermCondition: React.FC<{ modalState: boolean }> = ({ modalState }) => {
  const { register, handleSubmit } = useForm();
  const [ischecked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    await dispatch(updateTermOfServices());
  };

  return (
    <ModalPopup
      isOpen={modalState}
      className="term-modal h-auto rounded-lg transform translate-y-8 md:translate-y-24"
      shouldCloseOnOverlayClick={false}
      closebtnClass="hidden"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <img src={HOM_LOGO} alt="logo" />
        <div className="grid grid-cols-1">
          <label className="text-MobileFont text-Color_22 term-label pt-6 pb-4 text-center">
            Please take a few moments to read and understand our{' '}
            <a
              href={TERMS_CONDITION_LINK}
              className="text-Color_23"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Services
            </a>{' '}
            and our{' '}
            <a
              href={PRIVACY_POLICY}
              className="text-Color_23"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </label>
          <PrimaryLabel
            labelText="By checking, you agree to the Terms and HOM Privacy Policy"
            className="text-MobileFont term-label col-span-2 pt-0 pb-8 text-center"
          />
          <div className="col-span-2 flex pb-2">
            <input
              type="checkbox"
              className="checkbox"
              name="termCondition"
              checked={ischecked}
              onChange={() => setChecked(!ischecked)}
              ref={register}
            />
            <span className="text-Medium term-check">
              I agree to the terms and HOM Privacy Policy
            </span>
          </div>
          <Button
            label="Continue"
            className={`block btn-continue mt-4 ${!ischecked && 'btn-disable'}`}
          />
        </div>
      </form>
    </ModalPopup>
  );
};

export default TermCondition;
